import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import ReactDatePicker from 'react-datepicker'
import { CSSProperties } from 'react'

import Icon from '../Icon'

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #858585;
  cursor: 'pointer';
  display: block;
`

export const LabelWrapper = styled.div`
  margin: 0 0 6px 8px;
  vertical-align: middle;
  align-self: center;
`

export const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #354052;
`

export const Subtitle = styled.h2`
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #354052;
`

export const P = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #7f8fa4;
  display: inline-block;
  margin-bottom: 18px;
`

interface StyledInputProps {
  error?: boolean
  icon?: string
}

export const StyledInput = styled.input`
  border-radius: 20px;
  background-color: #f5f5f5;
  border: solid 1px #f5f5f5;
  padding: 12px 20px 12px 20px;
  outline: 0;
  color: #454545;
  width: 100%;

  &:focus {
    border: solid 1px #18b0f7;
  }

  &:disabled {
    background: #d1ecfa;
    border: solid 1px #d1ecfa;
    color: #18b0f7;
  }

  ::placeholder {
    color: #858585;
    opacity: 0.5;
  }

  ${(props: StyledInputProps) => {
    if (props.error) {
      return `
        background-color: rgba(255, 45, 45, 0.2);
        border-color: #ff2d2d;

        &:focus {
          border-color: #ff2d2d;
        }
      `
    }

    if (props.icon) {
      return `
        padding: 12px 35px 12px 20px;
      `
    }

    return null
  }};
`

interface StyledTextAreaProps {
  error?: boolean
}

export const StyledTextArea = styled.textarea`
  width: 100%;
  border-radius: 20px;
  background-color: #f5f5f5;
  border: solid 1px #f5f5f5;
  padding: 12px 20px;
  outline: 0;
  resize: none;
  color: #454545;

  &:focus {
    border: solid 1px #18b0f7;
  }

  ${(props: StyledTextAreaProps) => {
    if (props.error) {
      return `
        background-color: rgba(255, 45, 45, 0.2);
        border-color: #ff2d2d;

        &:focus {
          border-color: #ff2d2d;
        }
      `
    }

    return null
  }};
`

export const StyledCheckBoxWrapper = styled.div`
  display: flex;
  vertical-align: middle;
`

export const CheckIcon = styled(Icon)`
  color: #18b0f7;
  &::before {
    display: -webkit-box;
  }
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`

interface StyledCheckboxProps {
  checked?: boolean
  disabled?: boolean
  round?: boolean
}

export const StyledCheckBox = styled.div<StyledCheckboxProps>`
  display: flex;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: ${props => (props.round ? '50%' : '3px')};
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${props => {
      if (props.disabled || !props.checked) {
        return '#c5c5c5'
      }
      return '#18b0f7'
    }};

  ${CheckIcon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    color: ${props => {
      if (props.disabled || !props.checked) {
        return '#c5c5c5'
      }
      return '#18b0f7'
    }};
  }
`

export const StyledToggle = styled('span')<StyledCheckboxProps>`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: 0.4s;
  width: 45px;
  height: 24px;

  cursor: pointer;
  background-color: #ccc;
  border-radius: ${p => (p.round ? '12px' : '0')};

  ${HiddenCheckbox}:checked + & {
    background-color: #3b97d3;
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 1px #3b97d3;
  }

  &::before {
    position: absolute;
    content: '';
    left: 4px;
    bottom: 4px;

    transition: 0.4s;

    height: 16px;
    width: 16px;
    background-color: white;
    border-radius: ${p => (p.round ? '50%' : '0')};

    ${HiddenCheckbox}:checked + & {
      transform: translateX(20px);
    }
  }
`

export const RadioIcon = styled('div')`
  width: 80%;
  height: 80%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
`

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  clip: rect(0 0 0 0);
  padding: 0;
  position: absolute;
`

export const StyledRadio = styled.div<StyledCheckboxProps>`
  display: flex;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${props => {
      if (props.disabled || !props.checked) {
        return '#c5c5c5'
      }
      return '#18b0f7'
    }};

  ${RadioIcon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    background: ${props => {
      if (props.disabled || !props.checked) {
        return '#c5c5c5'
      }
      return '#18b0f7'
    }};
  }
`

interface SelectState {
  isFocused: boolean
  isSelected: boolean
}

export const selectStyles = (error?: boolean, disabled?: boolean) => ({
  control: (base: any, { isFocused }: SelectState): CSSProperties => {
    const styles = {
      display: 'flex',
      minHeight: '40px',
      backgroundColor: '#f5f5f5',
      borderRadius: '20px',
      border: isFocused ? '1px solid #18b0f7' : '1px solid #f5f5f5',
      cursor: (disabled ? 'not-allowed' : 'pointer') + ' !important',
      'pointer-events': 'all !important',
    }
    if (error) {
      styles.border = '1px solid #ff2d2d'
      styles.backgroundColor = 'rgba(255, 45, 45, 0.2)'
    }

    return styles
  },
  valueContainer: (base: any): CSSProperties => ({
    ...base,
    padding: '6px 8px 6px 20px',
  }),
  placeholder: (): CSSProperties => ({
    color: '#454545',
    fontSize: '14px',
  }),
  indicatorsContainer: (base: any): CSSProperties => ({
    ...base,
    paddingRight: '5px',
  }),
  singleValue: (): CSSProperties => ({
    fontSize: '14px',
    color: '#454545',
  }),
  multiValue: (): CSSProperties => ({
    display: 'flex',
    backgroundColor: '#858585',
    borderRadius: '12px',
    padding: '2px 7px 2px 15px',
    margin: '2px',
  }),
  multiValueLabel: (): CSSProperties => ({
    color: '#fff',
    fontSize: 14,
  }),
  multiValueRemove: (): CSSProperties => ({
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '15px',
  }),
  menu: (): CSSProperties => ({
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: 3,
    backgroundColor: '#ffffff',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: '20px',
    overflow: 'hidden',
  }),
  menuList: (): CSSProperties => ({
    padding: 0,
  }),
  option: (
    base: any,
    { isFocused, isSelected }: SelectState
  ): CSSProperties => ({
    backgroundColor: isSelected ? '#18b0f7' : 'transparent',
    padding: '10px 20px',
    fontSize: '14px',
    color: isSelected ? '#ffffff' : '#858585',
  }),
})

export const RequiredMark = styled.span`
  color: #18b0f7;
  margin-left: 3px;
`

export const Tip = styled.div`
  float: left;
  border-radius: 10px;
`

export const InputGroup = styled.div`
  position: relative;
  outline: 0;
  width: 100%;
`

export const InputSpan = styled.span`
  position: absolute;
  font-size: 14px;
  line-height: 1;
  margin-top: 12px;
  margin-left: -38px;
`

export const DatePickerSpan = styled.span`
  position: absolute;
  font-size: 14px;
  line-height: 1;
  margin-top: 10px;
`

export const StyledTooltip = styled(ReactTooltip)`
  font-size: 12px;
  border-radius: 4px;
  background-color: #454545 !important;
  &.place-top {
    &:after {
      border-top-color: #454545 !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
`

export const StyledDatePicker = styled(ReactDatePicker)`
  border-radius: 20px;
  background-color: #f5f5f5;
  border: solid 1px #f5f5f5;
  padding: 12px 35px 12px 20px;
  outline: 0;
  color: #454545;
  width: 100%;

  &:focus {
    border: solid 1px #18b0f7;
  }

  &:disabled {
    background: #d1ecfa;
    border: solid 1px #d1ecfa;
    color: #18b0f7;
  }

  ${(props: StyledInputProps) => {
    if (props.error) {
      return `
        background-color: rgba(255, 45, 45, 0.2);
        border-color: #ff2d2d;

        &:focus {
          border-color: #ff2d2d;
        }
      `
    }

    if (props.icon) {
      return `
        padding: 12px 35px 12px 20px;
      `
    }

    return null
  }};
`

export const HelpIcon = styled(Icon)`
  font-size: 16px;
  color: #18b0f7;
`

export const InputIcon = styled(Icon)`
  font-size: 20px;
  color: #18b0f7;
`

interface DropdownIconProps {
  error?: boolean
}

export const DropdownIcon = styled(Icon)`
  font-size: 20px;
  color: ${(props: DropdownIconProps) => (props.error ? '#ff3b30' : '#18b0f7')};
`
export const StyledError = styled.small`
  font-size: 12px;
  color: #ff2d2d;
  height: 25px;
  display: block;
  margin: 5px 0 0 8px;
`

export const DatePickerContainer = styled.div`
  & .react-datepicker-wrapper {
    width: 100%;

    & .react-datepicker__input-container {
      width: 100%;
    }
  }
`
export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 45px;
  height: 24px;
  background-color: #c5c5c5;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  margin-right: 2rem;
  margin-top: 2rem;

  span {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  &:active {
    span {
      width: 26px;
    }
  }
`
export const SwitchCheckBox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  padding: 1rem;

  &:checked + ${SwitchLabel} {
    background-color: #18b0f7;
    span {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
`
