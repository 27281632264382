import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import PageWrapper from '../../../components/PageWrapper'
import CreateCategoryForm from './components/CreateCategoryForm'
import EditCategoryForm from './components/EditCategoryForm'

interface RouteParams {
  id?: string
  action: string
}

const GET_CATEGORY_QUERY = gql`
  query getCategoryQuery($id: ID!) {
    category(id: $id) {
      id
      slug
      name
      description
      parent {
        id
        name
      }
      status
    }
  }
`

interface Data {
  category: any
}
class Category extends React.Component<RouteComponentProps<RouteParams>> {
  onCategorySaved = () => {
    this.props.history.push('/categories')
  }

  render() {
    const { id, action } = this.props.match.params

    return (
      <PageWrapper
        title={`${action === 'create' ? 'Crear' : 'Editar'} Categoria`}
      >
        {action === 'create' ? (
          <CreateCategoryForm onCreated={this.onCategorySaved} />
        ) : (
          <Query
            variables={{ id }}
            query={GET_CATEGORY_QUERY}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return null
              }

              if (error) {
                return null
              }

              if (!data) {
                return null
              }

              return (
                <EditCategoryForm
                  category={data.category}
                  onUpdated={this.onCategorySaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(Category)
