import React from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import qs from 'qs'
import { Query, QueryResult, Mutation } from 'react-apollo'
import { PAGE_SIZE_OPTIONS } from '../../../../config'
import { PageTopSection, RecreateTracking } from '../style'
import DataTable from '../../../../components/DataTable'
import { Button } from '../../../../components/Buttons'
import * as Grid from '../../../../components/Grid'
import { parsePageParams } from '../../../../helpers/params'
import ThemeContext from '../../../../context/ThemeContext'
import Paper from '../../../../components/Paper'

const GET_FULFILLMENT_ITEMS_QUERY = gql`
  query getFulfillmentOrderItems(
    $orderID: ID!
    $options: FulfillmentItemsOptions!
    $filters: FulfillmentItemsFilters!
  ) {
    fulfillmentsItems(orderID: $orderID, options: $options, filters: $filters) {
      nodes {
        id
        trackingCode
        items {
          purchase {
            reference
            productName
            total
            customer {
              firstName
              lastName
              identificationNumber
            }
          }
        }
      }
      pagination {
        total
      }
    }
  }
`

const RECREATE_TRACKING_CODE = gql`
  mutation recreateFulfillmentTrackingCode(
    $input: RecreateFulfillmentTrackingCodeInput!
  ) {
    recreateFulfillmentTrackingCode(input: $input)
  }
`

type PurchaseOrderItemsNode = Record<string, any>
interface Data {
  fulfillmentsItems: {
    nodes: PurchaseOrderItemsNode[]
    pagination: {
      total: number
    }
  }
}

interface RouterParams {
  id: string
}

class FulfillmentOrderItmesTable extends React.Component<
  RouteComponentProps<RouterParams>
> {
  extractPageParams = () => {
    const { page, page_size, s, sort_by } = parsePageParams(
      this.props.location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
    }
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText
    params.page = 1
    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()
    params.page_size = pageSize
    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  onPageChange = (page: number) => {
    const params = this.extractPageParams()

    params.page = page

    this.updatePageParams(params)
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  render() {
    const { page, page_size, s, sort_by } = this.extractPageParams()
    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]
    let flag = false
    let errorSave = false

    return (
      <Query
        variables={{
          orderID: this.props.match.params.id,
          options: {
            limit: pageSize,
            offset: page ? (page - 1) * pageSize : 0,
            sortBy: sort_by,
          },
          filters: { s },
        }}
        query={GET_FULFILLMENT_ITEMS_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }: QueryResult<Data, any>) => {
          if (loading) {
            return <p>Loading...</p>
          }
          if (error) {
            return <p>Error</p>
          }
          if (!data) {
            return <p>No Data</p>
          }
          const { nodes, pagination } = data.fulfillmentsItems

          nodes.forEach(po => {
            let tc: string
            tc = po.trackingCode
            if (tc === null || tc.length === 0 || tc === '') {
              flag = true
            }
          })
          const columns = [
            {
              header: 'Número de guía',
              key: 'trackingCode',
              sortable: false,
              Cell: (purchaseOrderItem: PurchaseOrderItemsNode) => {
                return purchaseOrderItem.trackingCode
              },
            },
            {
              header: 'Nombre del producto',
              key: 'productName',
              sortable: false,
              Cell: (purchaseOrderItem: PurchaseOrderItemsNode) => {
                return (
                  purchaseOrderItem.items[0] &&
                  purchaseOrderItem.items[0].purchase &&
                  purchaseOrderItem.items[0].purchase.productName
                )
              },
            },
            {
              header: 'Referencia',
              key: 'reference',
              sortable: false,
              Cell: (purchaseOrderItem: PurchaseOrderItemsNode) => {
                return (
                  purchaseOrderItem.items[0] &&
                  purchaseOrderItem.items[0].purchase &&
                  purchaseOrderItem.items[0].purchase.reference
                )
              },
            },
            {
              header: 'Identificación',
              key: 'identificationNumber',
              sortable: false,
              Cell: (purchaseOrderItem: PurchaseOrderItemsNode) => {
                return (
                  purchaseOrderItem.items[0] &&
                  purchaseOrderItem.items[0].purchase &&
                  purchaseOrderItem.items[0].purchase.customer &&
                  purchaseOrderItem.items[0].purchase.customer
                    .identificationNumber
                )
              },
            },
          ]
          return (
            <Mutation
              mutation={RECREATE_TRACKING_CODE}
              onCompleted={() => {
                refetch()
                flag = false
              }}
            >
              {(generateTrackingCode, { loading, called, error }) => (
                <React.Fragment>
                  <ThemeContext.Consumer>
                    {({ forceShowNotification }) => (
                      <>
                        <Paper>
                          <DataTable
                            indexKey="id"
                            columns={columns}
                            data={nodes}
                            loading={false}
                            totalItemsCount={pagination.total}
                            sortBy={sort_by}
                            page={page || 1}
                            pageSize={pageSize}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            searchText={s}
                            onSort={this.onSortByChange}
                            onPageChange={this.onPageChange}
                            onPageSizeChange={this.onPageSizeChange}
                            onSearch={this.onSearch}
                            placeholderSearchBar={
                              'Buscar por nombre de producto'
                            }
                          />
                        </Paper>
                        <PageTopSection>
                          <Grid.Row>
                            <Grid.Column md={12}>
                              <RecreateTracking>
                                {flag ? (
                                  <Button
                                    color="primary"
                                    disabled={loading ? true : false}
                                    onClick={() => {
                                      generateTrackingCode({
                                        variables: {
                                          input: {
                                            purchaseOrderId: this.props.match
                                              .params.id,
                                          },
                                        },
                                      })
                                        .catch(() => {
                                          errorSave = true
                                          forceShowNotification &&
                                            forceShowNotification({
                                              type: 'fail',
                                              message:
                                                'Error al generar número de guía',
                                            })
                                        })
                                        .finally(() => {
                                          !error &&
                                            !errorSave &&
                                            forceShowNotification &&
                                            forceShowNotification({
                                              type: 'ok',
                                              message:
                                                'Se generaron correctamente los números de guía',
                                            })
                                        })
                                    }}
                                  >
                                    {loading
                                      ? 'Generando...'
                                      : 'Generar número de guía'}
                                  </Button>
                                ) : null}
                              </RecreateTracking>
                            </Grid.Column>
                          </Grid.Row>
                        </PageTopSection>
                      </>
                    )}
                  </ThemeContext.Consumer>
                </React.Fragment>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(FulfillmentOrderItmesTable)
