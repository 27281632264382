import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'

import * as Grid from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import PaperVariation from '../../../../components/PaperEditVariation'
import FormSection from '../../../../components/FormSection'
import {
  Spacer,
  ActionWrapper,
  // , EditIcon
} from '../../../../components/Globals'
import { Product } from './ProductSelector'
import {
  Input,
  Select,
  Error,
  // Tooltip,
} from '../../../../components/FormElements'
import AssetSelector, { Asset } from '../../../../components/AssetSelector'

import { round } from '../../../../helpers/utils'
import {
  validateField,
  InputElements,
  getElements,
  validateForm,
  Field,
  initialField,
  scrollTo,
} from '../../../../helpers/validations'
import { Button, CancelButton } from '../../../../components/Buttons'
import { STATUS_OPTIONS } from '../../../../config'
import Modal from '../../../../components/Modals/ModalRoot'
import { IconButton } from '../../../../components/Buttons'
import MetadataGroup from '../components/MetadataGroup'

import {
  InputTitleWrapper,
  Description,
  Summary,
  AddFeatureWrapper,
  AddFeatureText,
} from '../style'

import ThemeContext from '../../../../context/ThemeContext'
// import { Link } from 'react-router-dom'

const EDIT_VARIATION_MUTATION = gql`
  mutation editVariationMutation($input: EditVariationInput!) {
    editVariation(input: $input)
  }
`
const UPLOAD_VARIATION_FILE = gql`
  mutation uploadVariationFile($file: Upload!) {
    uploadVariationFile(file: $file) {
      url
      fileName
    }
  }
`

const DELETE_FILE = gql`
  mutation deleteFile($id: String) {
    deleteFile(id: $id)
  }
`
interface Props {
  onUpdated: () => void
  variation: any
}

interface Metadata {
  key: Field
  value: Field
}

interface State {
  product: Product | null
  options: Array<{ featureId: string | null; optionId: string | null }>
  supplierCost: Field
  supplierDiscount: Field
  supplierTaxes: Field
  supplierSpecialTaxes: Field
  courierCost: Field
  profit: Field
  taxes: Field
  price: Field
  totalPrice: Number
  regularPrice: Field
  refundPrice: Field
  cardProfit: Field
  mileCost: number
  courierTaxes: Field
  reference: Field
  width: Field
  height: Field
  Length: Field
  weight: Field
  storageStock: Field
  localStock: Field
  arriveInDays: Field
  status: ValueType<string | number | { [key: string]: any }>
  availableStatuses: Array<{ value: string; label: string }>
  inputs: InputElements[]
  assets: Asset[]
  oldAssets: Asset[]
  deleteAssets: Asset[]
  assetsStatus: boolean
  metaData: JSON
  metaDataArray: Metadata[]
}
const inputIds = [
  'status',
  'arriveInDays',
  'localStock',
  'storageStock',
  'weight',
  'Length',
  'height',
  'width',
  'reference',
  'cardProfit',
  'refundPrice',
  'regularPrice',
  'taxes',
  'profit',
  'courierTaxes',
  'courierCost',
  'supplierSpecialTaxes',
  'supplierTaxes',
  'supplierDiscount',
  'supplierCost',
]
class EditVariationForm extends React.Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state)

    const results = STATUS_OPTIONS.filter(statusArray => {
      return statusArray.value === this.props.variation.status
    })
    const status =
      results.length > 0
        ? results[0]
        : {
            value: 'inactive',
            label: 'Inactivo',
          }

    const {
      product,
      options,
      supplierCost,
      supplierDiscount,
      supplierTaxes,
      supplierSpecialTaxes,
      courierCost,
      profit,
      taxes,
      courierTaxes,
      price,
      regularPrice,
      refundPrice,
      cardProfit,
      reference,
      width,
      height,
      length,
      weight,
      inventory,
      arriveInDays,
      assets,
      metaData,
    } = this.props.variation

    this.state = {
      product: product,
      options: options || [],
      supplierCost: {
        ...initialField,
        value: supplierCost,
      },
      supplierDiscount: {
        ...initialField,
        value: supplierDiscount,
      },
      supplierTaxes: {
        ...initialField,
        value: supplierTaxes,
      },
      supplierSpecialTaxes: {
        ...initialField,
        value: supplierSpecialTaxes,
      },
      courierCost: {
        ...initialField,
        value: courierCost,
      },
      profit: {
        ...initialField,
        value: profit,
      },
      taxes: {
        ...initialField,
        value: taxes,
      },
      mileCost: 0.0125,
      courierTaxes: {
        ...initialField,
        value: courierTaxes,
      },
      price: {
        ...initialField,
        value: price,
      },
      totalPrice: price,
      regularPrice: {
        ...initialField,
        value: regularPrice,
      },
      refundPrice: {
        ...initialField,
        value: refundPrice,
      },
      cardProfit: {
        ...initialField,
        value: cardProfit,
      },
      reference: {
        ...initialField,
        value: reference,
      },
      width: {
        ...initialField,
        value: width,
      },
      height: {
        ...initialField,
        value: height,
      },
      Length: {
        ...initialField,
        value: length,
      },
      weight: {
        ...initialField,
        value: weight,
      },
      storageStock: {
        ...initialField,
        value: inventory.storageStock,
      },
      localStock: {
        ...initialField,
        value: inventory.localStock,
      },
      arriveInDays: {
        ...initialField,
        value: arriveInDays,
      },
      status: { value: status.value, label: status.label },
      availableStatuses: [
        { value: 'active', label: 'Activo' },
        { value: 'inactive', label: 'Inactivo' },
        { value: 'discontinued', label: 'Descontinuado' },
      ],
      assets: assets
        ? assets.map((asset: any) => {
            return {
              id: asset.id,
              url: asset.url,
              type: asset.type,
              order: asset.order,
            }
          })
        : [],
      oldAssets: assets
        ? assets.map((asset: any) => {
            return {
              id: asset.id,
              url: asset.url,
              type: asset.type,
              order: asset.order,
            }
          })
        : [],
      deleteAssets: [],
      assetsStatus: false,
      metaData: metaData,
      metaDataArray: metaData
        ? Object.keys(metaData).map(item => {
            return {
              key: { ...initialField, value: item },
              value: { ...initialField, value: metaData[item] },
            }
          })
        : [],
      inputs: [],
    }
  }

  handleSupplierCostChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const supplierCost = value

    if (isNaN(Number(supplierCost))) {
      return
    }

    const validations = validateField(supplierCost, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThan',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 99999.999,
      },
    ])

    if (validations.value) {
      this.setState({
        supplierCost: {
          value: supplierCost,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState(
      {
        supplierCost: {
          value: supplierCost,
          error: false,
          errorText: '',
        },
      },
      () => {
        this.validateRegularPrice()
      }
    )
  }

  handlePriceChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const price = value

    if (isNaN(Number(price))) {
      return
    }

    const validations = validateField(price, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThan',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 99999.999,
      },
    ])

    if (validations.value) {
      this.setState({
        price: {
          value: price,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      price: {
        value: price,
        error: false,
        errorText: '',
      },
    })
  }

  handleTotalPriceChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const price = value

    this.setState({
      totalPrice: Number(price),
    })
  }

  handleRegularPriceChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const regularPrice = value
    const { totalPrice } = this.state

    if (isNaN(Number(regularPrice))) {
      return
    }

    const validations = validateField(regularPrice, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThan',
        value: totalPrice,
      },
      {
        name: 'lessThan',
        value: 99999.999,
      },
    ])

    if (
      validations.value &&
      (Number(regularPrice) != 0 && regularPrice != null)
    ) {
      this.setState({
        regularPrice: {
          value: regularPrice,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      regularPrice: {
        value: regularPrice,
        error: false,
        errorText: '',
      },
    })
  }

  handleRefundPriceChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const refundPrice = value
    if (isNaN(Number(refundPrice))) {
      return
    }

    const validations = validateField(refundPrice, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'lessThan',
        value: 99999.999,
      },
    ])

    if (validations.value && Number(refundPrice) != 0) {
      this.setState({
        refundPrice: {
          value: refundPrice,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      refundPrice: {
        value: refundPrice,
        error: false,
        errorText: '',
      },
    })
  }

  handleCardProfitChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const cardProfit = value
    if (isNaN(Number(cardProfit))) {
      return
    }

    const validations = validateField(cardProfit, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 99999.999,
      },
    ])

    if (validations.value) {
      this.setState({
        cardProfit: {
          value: cardProfit,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      cardProfit: {
        value: cardProfit,
        error: false,
        errorText: '',
      },
    })
  }

  handleSupplierDiscountChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const supplierDiscount = value

    if (isNaN(Number(supplierDiscount))) {
      return
    }

    const validations = validateField(supplierDiscount, [
      {
        name: 'decimalCount',
        value: 10,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 100,
      },
    ])

    if (validations.value) {
      this.setState({
        supplierDiscount: {
          value: supplierDiscount,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      supplierDiscount: {
        value: supplierDiscount,
        error: false,
        errorText: '',
      },
    })
  }

  handleCourierTaxesChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const courierTaxes = value

    if (isNaN(Number(value))) {
      return
    }

    const validations = validateField(courierTaxes, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 999.99,
      },
    ])

    if (validations.value) {
      this.setState({
        courierTaxes: {
          value: courierTaxes,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      courierTaxes: {
        value: courierTaxes,
        error: false,
        errorText: '',
      },
    })
  }

  handleTaxesChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const taxes = value

    if (isNaN(Number(value))) {
      return
    }

    const validations = validateField(taxes, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 999.99,
      },
    ])

    if (validations.value) {
      this.setState({
        taxes: {
          value: taxes,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      taxes: {
        value: taxes,
        error: false,
        errorText: '',
      },
    })
  }

  handleSupplierTaxesChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const supplierTaxes = value

    if (isNaN(Number(value))) {
      return
    }

    const validations = validateField(supplierTaxes, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 999.99,
      },
    ])

    if (validations.value) {
      this.setState({
        supplierTaxes: {
          value: supplierTaxes,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      supplierTaxes: {
        value: supplierTaxes,
        error: false,
        errorText: '',
      },
    })
  }

  handleSupplierSpecialTaxesChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const SupplierSpecialTaxes = value

    if (isNaN(Number(value))) {
      return
    }

    const validations = validateField(SupplierSpecialTaxes, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 999.99,
      },
    ])

    if (validations.value) {
      this.setState({
        supplierSpecialTaxes: {
          value: SupplierSpecialTaxes,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      supplierSpecialTaxes: {
        value: SupplierSpecialTaxes,
        error: false,
        errorText: '',
      },
    })
  }

  handleCourierCostChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const courierCost = value
    if (isNaN(Number(courierCost))) {
      return
    }

    const validations = validateField(courierCost, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 99999.999,
      },
    ])

    if (validations.value) {
      this.setState({
        courierCost: {
          value: courierCost,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      courierCost: {
        value: courierCost,
        error: false,
        errorText: '',
      },
    })
  }

  handleProfitChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const profit = value
    if (isNaN(Number(profit))) {
      return
    }
    const validations = validateField(profit, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 999.99,
      },
    ])

    if (validations.value) {
      this.setState({
        profit: {
          value: profit,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      profit: {
        value: profit,
        error: false,
        errorText: '',
      },
    })
  }

  handleReferenceChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const reference = value

    const validations = validateField(reference, [
      {
        name: 'shorterThan',
        value: 50,
      },
    ])

    if (validations.value) {
      this.setState({
        reference: {
          value: reference,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      reference: {
        value: reference,
        error: false,
        errorText: '',
      },
    })
  }

  handleWidthChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const width = value
    if (isNaN(Number(width))) {
      return
    }

    const validations = validateField(width, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 999.99,
      },
    ])

    if (validations.value) {
      this.setState({
        width: {
          value: width,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      width: {
        value: width,
        error: false,
        errorText: '',
      },
    })
  }

  handleHeightChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const height = value
    if (isNaN(Number(height))) {
      return
    }
    const validations = validateField(height, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 999.99,
      },
    ])

    if (validations.value) {
      this.setState({
        height: {
          value: height,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      height: {
        value: height,
        error: false,
        errorText: '',
      },
    })
  }

  handleLengthChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const length = value
    if (isNaN(Number(length))) {
      return
    }

    const validations = validateField(length, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 999.99,
      },
    ])

    if (validations.value) {
      this.setState({
        Length: {
          value: length,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      Length: {
        value: length,
        error: false,
        errorText: '',
      },
    })
  }

  handleWeightChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const weight = value
    if (isNaN(Number(weight))) {
      return
    }

    const validations = validateField(weight, [
      {
        name: 'decimalCount',
        value: 5,
      },
      {
        name: 'greaterThan',
        value: 0,
      },
      {
        name: 'lessThan',
        value: 999.99,
      },
    ])

    if (validations.value) {
      this.setState({
        weight: {
          value: weight,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      weight: {
        value: weight,
        error: false,
        errorText: '',
      },
    })
  }

  handleStorageStockChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const storageStock = value
    if (isNaN(Number(storageStock))) {
      return
    }

    const validations = validateField(storageStock, [
      {
        name: 'decimalCount',
        value: 0,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
    ])

    if (validations.value) {
      this.setState({
        storageStock: {
          value: storageStock,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState(
      {
        storageStock: {
          value: storageStock,
          error: false,
          errorText: '',
        },
      },
      () => {
        this.validateStock()
      }
    )
  }

  handleLocalStockChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const localStock = value
    if (isNaN(Number(localStock))) {
      return
    }

    const validations = validateField(localStock, [
      {
        name: 'decimalCount',
        value: 0,
      },
      {
        name: 'greaterThanEqual',
        value: 0,
      },
    ])

    if (validations.value) {
      this.setState({
        localStock: {
          value: localStock,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState(
      {
        localStock: {
          value: localStock,
          error: false,
          errorText: '',
        },
      },
      () => {
        this.validateStock()
      }
    )
  }

  handleArriveInDaysChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const arriveInDays = value
    if (isNaN(Number(arriveInDays))) {
      return
    }

    const validations = validateField(arriveInDays, [
      {
        name: 'decimalCount',
        value: 0,
      },
      {
        name: 'greaterThan',
        value: 0,
      },
    ])

    if (validations.value) {
      this.setState({
        arriveInDays: {
          value: arriveInDays,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      arriveInDays: {
        value: arriveInDays,
        error: false,
        errorText: '',
      },
    })
  }

  handleStatusChange = (
    status: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({ status })
  }

  discount = (supplierCost: string, supplierDiscount: string) => {
    supplierCost = Number(supplierCost).toString()
    supplierDiscount = Number(supplierDiscount).toString()
    return round(
      parseFloat(supplierCost) * (parseFloat(supplierDiscount) / 100),
      5
    )
  }

  totalDiscount = (supplierCost: string, supplierDiscount: string) => {
    supplierCost = Number(supplierCost).toString()
    supplierDiscount = Number(supplierDiscount).toString()
    const discount = this.discount(supplierCost, supplierDiscount)
    return round(parseFloat(supplierCost) - discount, 5)
  }

  subtotalSupplierTaxes = (
    supplierCost: string,
    supplierDiscount: string,
    supplierTaxes: string
  ) => {
    supplierCost = Number(supplierCost).toString()
    supplierDiscount = Number(supplierDiscount).toString()
    supplierTaxes = Number(supplierTaxes).toString()
    const totalDiscount = this.totalDiscount(supplierCost, supplierDiscount)
    return round(totalDiscount * (parseFloat(supplierTaxes) / 100), 5)
  }

  subtotalSupplierSpecialTaxes = (
    supplierCost: string,
    supplierDiscount: string,
    supplierSpecialTaxes: string
  ) => {
    supplierCost = Number(supplierCost).toString()
    supplierDiscount = Number(supplierDiscount).toString()
    supplierSpecialTaxes = Number(supplierSpecialTaxes).toString()
    const totalDiscount = this.totalDiscount(supplierCost, supplierDiscount)
    return round(totalDiscount * (parseFloat(supplierSpecialTaxes) / 100), 5)
  }

  totalSupplier = (
    supplierCost: string,
    supplierDiscount: string,
    supplierTaxes: string,
    supplierSpecialTaxes: string
  ) => {
    supplierCost = Number(supplierCost).toString()
    supplierDiscount = Number(supplierDiscount).toString()
    supplierTaxes = Number(supplierTaxes).toString()
    supplierSpecialTaxes = Number(supplierSpecialTaxes).toString()
    const totalDiscount = this.totalDiscount(supplierCost, supplierDiscount)
    const subtotalSupplierTaxes = this.subtotalSupplierTaxes(
      supplierCost,
      supplierDiscount,
      supplierTaxes
    )
    const subtotalSupplierSpecialTaxes = this.subtotalSupplierSpecialTaxes(
      supplierCost,
      supplierDiscount,
      supplierSpecialTaxes
    )

    return round(
      totalDiscount + subtotalSupplierTaxes + subtotalSupplierSpecialTaxes,
      5
    )
  }

  totalCourier = (courierCost: string, courierTaxes: string) => {
    courierCost = Number(courierCost).toString()
    courierTaxes = Number(courierTaxes).toString()
    return round(parseFloat(courierCost) * (1 + Number(courierTaxes) / 100), 5)
  }

  totalInsured = (supplierCost: string) => {
    supplierCost = Number(supplierCost).toString()
    return round(parseFloat(supplierCost), 5)
  }

  totalProfit = (supplierCost: string, profit: string) => {
    supplierCost = Number(supplierCost).toString()
    profit = Number(profit).toString()
    const totalInsured = this.totalInsured(supplierCost)
    return round(totalInsured * (parseFloat(profit) / 100), 5)
  }

  pricetotal = (
    supplierCost: string,
    supplierDiscount: string,
    profit: string,
    courierCost: string,
    taxes: string
  ) => {
    const { cardProfit, refundPrice } = this.state
    const cardProfitStr = isNaN(Number(cardProfit.value))
      ? '0'
      : Number(cardProfit.value).toString()
    supplierCost = Number(supplierCost).toString()
    supplierDiscount = Number(supplierDiscount).toString()
    profit = Number(profit).toString()
    courierCost = Number(courierCost).toString()
    taxes = Number(taxes).toString()
    const subtotalProfit = this.totalProfit(supplierCost, profit)
    const subtotalSupplier = this.totalDiscount(supplierCost, supplierDiscount)
    const refundPriceSum = Number(refundPrice.value).toString()

    const price = round(
      (Number(courierCost) + subtotalProfit + subtotalSupplier) *
        (1 + Number(taxes) / 100) -
        Number(refundPriceSum),
      5
    )

    return price + Number(cardProfitStr)
  }

  validateStock() {
    const { localStock, storageStock } = this.state

    let validation: Field = {
      value: '',
      error: false,
      errorText: '',
    }
    if (Number(localStock.value) + Number(storageStock.value) < 0) {
      validation.error = true
      validation.errorText = 'La suma de ambos stock debe ser mayor o igual a 0'
    }
    this.setState({
      storageStock: {
        value: storageStock.value,
        error: validation.error,
        errorText: validation.errorText,
      },
      localStock: {
        value: localStock.value,
        error: validation.error,
        errorText: validation.errorText,
      },
    })
    return validation.error
  }

  validateRegularPrice() {
    const {
      supplierCost,
      supplierDiscount,
      profit,
      courierCost,
      taxes,
      regularPrice,
    } = this.state

    const price = this.pricetotal(
      supplierCost.value,
      supplierDiscount.value,
      profit.value,
      courierCost.value,
      taxes.value
    )

    let validation: Field = {
      value: '',
      error: false,
      errorText: '',
    }
    if (regularPrice.value && Number(regularPrice.value) <= price) {
      validation.error = true
      validation.errorText = 'Ingresa un valor mayor a ' + price
    }
    this.setState({
      regularPrice: {
        value: regularPrice.value,
        error: validation.error,
        errorText: validation.errorText,
      },
    })
    return validation.error
  }

  validateMetadata = () => {
    this.setState({
      metaDataArray: this.state.metaDataArray.map(item => {
        if (item.key && item.key.value.trim() == '') {
          item.key.error = true
          item.key.errorText = 'Campo Requerido'
        }
        if (item.value && item.value.value.trim() == '') {
          item.value.error = true
          item.value.errorText = 'Campo Requerido'
        }
        return item
      }),
    })
  }

  scrollToError = () => {
    const { inputs } = this.state
    let { error, errorsMap } = validateForm(inputs, inputIds)
    this.setState(prevState => {
      for (const key in errorsMap) {
        prevState[key] = errorsMap[key]
      }
      return prevState
    })

    inputIds.map(id => {
      if (this.state[id] && this.state[id].error) {
        scrollTo(inputs[id].element)
        error = false
      }
    })

    this.state.metaDataArray.map((item, index) => {
      if ((item.key && item.key.error) || (item.value && item.value.error)) {
        const element = document.getElementById('key-' + index)
        if (element) {
          scrollTo(element)
        }
        error = false
      }
    })

    return error
  }

  async mapElements() {
    const update = getElements(inputIds)
    this.setState({ inputs: update })
  }

  public componentDidMount() {
    this.mapElements()
    this.validateRegularPrice()
  }

  handleAssetsChange = (assets: Asset[]) => {
    const { oldAssets } = this.state
    const deleteAssets = oldAssets.filter(
      (oldAsset: Asset) =>
        assets.map((asset: Asset) => asset.id).indexOf(oldAsset.id) === -1
    )
    this.setState({ assets, assetsStatus: true, deleteAssets })
  }

  addItemToMetaData = () => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux.push({
      key: { ...initialField },
      value: { ...initialField },
    })
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  removeMetada = (index: number) => {
    const metaDataArrayAux = this.state.metaDataArray
    const result = [
      ...metaDataArrayAux.slice(0, index),
      ...metaDataArrayAux.slice(index + 1),
    ]
    this.generateMetadataJson(result)
    this.setState({ metaDataArray: result })
  }

  handleKeyMetada = (index: number, key: string) => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux[index].key = {
      value: key,
      error: key.trim() == '' ? true : false,
      errorText: key.trim() == '' ? 'Campo Requerido' : '',
    }
    this.generateMetadataJson(metaDataArrayAux)
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  handleValueMetada = (index: number, value: string) => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux[index].value = {
      value: value,
      error: value.trim() == '' ? true : false,
      errorText: value.trim() == '' ? 'Campo Requerido' : '',
    }
    this.generateMetadataJson(metaDataArrayAux)
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  generateMetadataJson = (metadataArray: Metadata[]) => {
    let metadataJson = {}
    metadataArray.forEach(item => {
      metadataJson[item.key.value] = item.value.value
    })
    this.setState({ metaData: JSON.parse(JSON.stringify(metadataJson)) })
  }

  // validateMetada = () => {
  //   const metaDataArrayAux = this.state.metaDataArray
  //   this.state.metaDataArray.forEach((item) => {
  //     if (item.key.error == true || item.value.error == true) {
  //       return true
  //     }
  //     return false
  //   })
  // }

  render() {
    const {
      product,
      options,
      supplierCost,
      supplierDiscount,
      supplierTaxes,
      supplierSpecialTaxes,
      courierCost,
      courierTaxes,
      profit,
      taxes,
      mileCost,
      // price,
      regularPrice,
      refundPrice,
      cardProfit,
      reference,
      width,
      height,
      Length,
      weight,
      storageStock,
      localStock,
      arriveInDays,
      status,
      availableStatuses,
      assets,
      metaData,
      metaDataArray,
    } = this.state
    const { id } = this.props.variation
    return (
      <Modal>
        {({ openModal }) => {
          return (
            <React.Fragment>
              <FormSection
                title="Variaciones"
                subtitle="Seleccione una combinación con las características que se presentan a continuación"
                openedByDefault
              >
                <Paper>
                  <Grid.Row>
                    <Grid.Column md={8}>
                      <Input
                        value={product ? product.name : ''}
                        type="text"
                        disabled
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Spacer />
                  <Grid.Row>
                    {options &&
                      options.map((option: any, index) => (
                        <Grid.Column md={4} key={option.id}>
                          {`${option.feature.name}:`}
                          <br />
                          {`${option.name}`}
                        </Grid.Column>
                      ))}
                  </Grid.Row>
                </Paper>
              </FormSection>

              {product && (
                <React.Fragment>
                  <FormSection title="Costo Proveedor">
                    <PaperVariation>
                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>Costo Mercado:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="supplierCost"
                            type="number"
                            value={supplierCost.value}
                            onChange={this.handleSupplierCostChange}
                            error={supplierCost.error}
                          />
                          {supplierCost.error ? (
                            <Error>{supplierCost.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={supplierCost.value}
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>Descuento:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="supplierDiscount"
                            icon="percent"
                            type="number"
                            value={supplierDiscount.value}
                            onChange={this.handleSupplierDiscountChange}
                            error={supplierDiscount.error}
                          />
                          {supplierDiscount.error ? (
                            <Error>{supplierDiscount.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="discount"
                            type="number"
                            value={
                              Number(supplierCost.value) > 0 &&
                              Number(supplierDiscount.value) >= 0
                                ? this.discount(
                                    supplierCost.value,
                                    supplierDiscount.value
                                  )
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={7}>
                          <InputTitleWrapper>
                            <Summary>
                              <span>Subtotal: </span>
                            </Summary>
                            <Description>
                              <span>(Costo de mercado - Descuento)</span>
                            </Description>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="totalDiscount"
                            type="number"
                            value={
                              Number(supplierCost.value) > 0 &&
                              Number(supplierDiscount.value) >= 0
                                ? this.totalDiscount(
                                    supplierCost.value,
                                    supplierDiscount.value
                                  )
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Spacer />
                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>IVA:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="supplierTaxes"
                            icon="percent"
                            type="number"
                            value={supplierTaxes.value}
                            onChange={this.handleSupplierTaxesChange}
                            error={supplierTaxes.error}
                          />
                          {supplierTaxes.error ? (
                            <Error>{supplierTaxes.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={
                              Number(supplierCost.value) > 0 &&
                              Number(supplierDiscount.value) >= 0 &&
                              Number(supplierTaxes.value) >= 0
                                ? this.subtotalSupplierTaxes(
                                    supplierCost.value,
                                    supplierDiscount.value,
                                    supplierTaxes.value
                                  )
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>ICE:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="supplierSpecialTaxes"
                            icon="percent"
                            type="number"
                            value={supplierSpecialTaxes.value}
                            onChange={this.handleSupplierSpecialTaxesChange}
                            error={supplierSpecialTaxes.error}
                          />
                          {supplierSpecialTaxes.error ? (
                            <Error>{supplierSpecialTaxes.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={
                              Number(supplierCost.value) > 0 &&
                              Number(supplierDiscount.value) >= 0 &&
                              Number(supplierSpecialTaxes.value) >= 0
                                ? this.subtotalSupplierSpecialTaxes(
                                    supplierCost.value,
                                    supplierDiscount.value,
                                    supplierSpecialTaxes.value
                                  )
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={7}>
                          <InputTitleWrapper>
                            <Summary>
                              <span>Total Costo Proveedor: </span>
                            </Summary>
                            <Description>
                              <span>(Subtotal + IVA + ICE)</span>
                            </Description>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={
                              Number(supplierCost.value) > 0 &&
                              Number(supplierDiscount.value) >= 0 &&
                              (Number(supplierTaxes.value) >= 0 &&
                                Number(supplierSpecialTaxes.value) >= 0)
                                ? this.totalSupplier(
                                    supplierCost.value,
                                    supplierDiscount.value,
                                    supplierTaxes.value,
                                    supplierSpecialTaxes.value
                                  )
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </PaperVariation>
                  </FormSection>
                  <FormSection title="Costo Courier">
                    <PaperVariation>
                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>Costo Courier:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="courierCost"
                            type="number"
                            value={courierCost.value}
                            onChange={this.handleCourierCostChange}
                            error={courierCost.error}
                          />
                          {courierCost.error ? (
                            <Error>{courierCost.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            value={
                              Number(courierCost.value) >= 0
                                ? courierCost.value
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>IVA:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="courierTaxes"
                            type="number"
                            value={courierTaxes.value}
                            onChange={this.handleCourierTaxesChange}
                            error={courierTaxes.error}
                            icon="percent"
                          />
                          {courierTaxes.error ? (
                            <Error>{courierTaxes.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            value={
                              Number(courierCost.value) >= 0 &&
                              Number(courierTaxes.value) >= 0
                                ? round(
                                    parseFloat(courierCost.value) *
                                      (Number(courierTaxes.value) / 100),
                                    5
                                  )
                                : ''
                            }
                            type="number"
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Spacer />
                      <Grid.Row>
                        <Grid.Column md={7}>
                          <InputTitleWrapper>
                            <Summary>
                              <span>Total Courier:</span>
                            </Summary>
                            <Description>
                              <span>(Costo courier + IVA)</span>
                            </Description>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={
                              Number(courierCost.value) >= 0 &&
                              Number(courierTaxes.value) >= 0
                                ? this.totalCourier(
                                    courierCost.value,
                                    courierTaxes.value
                                  )
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </PaperVariation>
                  </FormSection>

                  <FormSection title="Utilidad">
                    <PaperVariation>
                      <Grid.Row>
                        <Grid.Column md={7}>
                          <InputTitleWrapper>
                            <Summary>
                              <span>Valor Asegurado:</span>
                            </Summary>
                            <Description>
                              <span>(Costo mercado)</span>
                            </Description>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={
                              Number(supplierCost.value) > 0
                                ? this.totalInsured(supplierCost.value)
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Spacer />
                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>Valor Utilidad:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="profit"
                            icon="percent"
                            type="number"
                            value={profit.value}
                            onChange={this.handleProfitChange}
                            error={profit.error}
                          />
                          {profit.error ? (
                            <Error>{profit.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            value={
                              Number(supplierCost.value) > 0 &&
                              Number(profit.value) >= 0
                                ? this.totalProfit(
                                    supplierCost.value,
                                    profit.value
                                  )
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </PaperVariation>
                  </FormSection>
                  <FormSection title="PVP">
                    <PaperVariation>
                      <Grid.Row>
                        <Grid.Column md={7}>
                          <InputTitleWrapper>
                            <Summary>
                              <span>Subtotal: </span>
                            </Summary>
                            <Description>
                              <span>
                                (Subtotal proveedor + Subtotal Courier +
                                Utilidad)
                              </span>
                            </Description>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={
                              Number(supplierCost.value) > 0 &&
                              Number(supplierDiscount.value) >= 0 &&
                              Number(profit.value) >= 0 &&
                              Number(courierCost.value) >= 0
                                ? round(
                                    this.totalDiscount(
                                      supplierCost.value,
                                      supplierDiscount.value
                                    ) +
                                      Number(courierCost.value) +
                                      this.totalProfit(
                                        supplierCost.value,
                                        profit.value
                                      ),
                                    5
                                  )
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Spacer />
                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>IVA:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="taxes"
                            type="number"
                            value={taxes.value}
                            onChange={this.handleTaxesChange}
                            error={taxes.error}
                            icon="percent"
                          />
                          {taxes.error ? (
                            <Error>{taxes.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={
                              Number(supplierCost.value) > 0 &&
                              Number(supplierDiscount.value) >= 0 &&
                              Number(profit.value) >= 0 &&
                              Number(courierCost.value) >= 0 &&
                              Number(taxes.value) >= 0
                                ? round(
                                    (this.totalDiscount(
                                      supplierCost.value,
                                      supplierDiscount.value
                                    ) +
                                      Number(courierCost.value) +
                                      this.totalProfit(
                                        supplierCost.value,
                                        profit.value
                                      )) *
                                      (Number(taxes.value) / 100),
                                    5
                                  )
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>Utilidad Tarjeta:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="cardProfit"
                            type="text"
                            value={cardProfit.value}
                            onChange={this.handleCardProfitChange}
                            error={cardProfit.error}
                          />
                          {cardProfit.error ? (
                            <Error>{cardProfit.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>Precio de Reembolso:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="refundPrice"
                            type="text"
                            value={refundPrice.value}
                            onChange={this.handleRefundPriceChange}
                            error={refundPrice.error}
                          />
                          {refundPrice.error ? (
                            <Error>{refundPrice.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Spacer />
                      <Grid.Row>
                        <Grid.Column md={7}>
                          <InputTitleWrapper>
                            <Summary>
                              <span>Total:</span>
                            </Summary>
                            <Description>
                              <span>
                                (Subtotal + IVA + U. Tarjeta - Reembolso)
                              </span>
                            </Description>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={
                              Number(supplierCost.value) > 0 &&
                              Number(supplierDiscount.value) >= 0 &&
                              Number(profit.value) >= 0 &&
                              Number(courierCost.value) >= 0 &&
                              Number(taxes.value) >= 0
                                ? this.pricetotal(
                                    supplierCost.value,
                                    supplierDiscount.value,
                                    profit.value,
                                    courierCost.value,
                                    taxes.value
                                  )
                                : ''
                            }
                            onChange={this.handleTotalPriceChange}
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Spacer />
                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>Conversión:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={mileCost}
                            icon="percent"
                            disabled
                          />
                        </Grid.Column>

                        <Grid.Column md={5}>
                          <Input
                            type="number"
                            value={
                              Number(supplierCost.value) > 0 &&
                              Number(supplierDiscount.value) >= 0 &&
                              Number(profit.value) >= 0 &&
                              Number(courierCost.value) >= 0 &&
                              Number(taxes.value) >= 0
                                ? round(
                                    this.pricetotal(
                                      supplierCost.value,
                                      supplierDiscount.value,
                                      profit.value,
                                      courierCost.value,
                                      taxes.value
                                    ) / mileCost,
                                    5
                                  )
                                : ''
                            }
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Spacer />
                      <Grid.Row>
                        <Grid.Column md={2}>
                          <InputTitleWrapper>
                            <span>Precio Regular:</span>
                          </InputTitleWrapper>
                        </Grid.Column>
                        <Grid.Column md={5}>
                          <Input
                            id="regularPrice"
                            type="text"
                            value={regularPrice.value}
                            onChange={this.handleRegularPriceChange}
                            error={regularPrice.error}
                          />
                          {regularPrice.error ? (
                            <Error>{regularPrice.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </PaperVariation>
                  </FormSection>

                  <FormSection title="Información específica">
                    <Paper>
                      <Grid.Row>
                        <Grid.Column md={4}>
                          <Input
                            id="reference"
                            type="text"
                            value={reference.value}
                            onChange={this.handleReferenceChange}
                            label="Referencia"
                            required
                            error={reference.error}
                          />
                          {reference.error ? (
                            <Error>{reference.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Input
                            id="width"
                            type="number"
                            value={width.value}
                            onChange={this.handleWidthChange}
                            label="Ancho (cm)"
                            error={width.error}
                          />
                          {width.error ? (
                            <Error>{width.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Input
                            id="height"
                            type="number"
                            value={height.value}
                            onChange={this.handleHeightChange}
                            label="Alto (cm)"
                            error={height.error}
                          />
                          {height.error ? (
                            <Error>{height.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column md={4}>
                          <Input
                            id="Length"
                            type="number"
                            value={Length.value}
                            onChange={this.handleLengthChange}
                            label="Largo (cm)"
                            error={Length.error}
                          />
                          {Length.error ? (
                            <Error>{Length.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Input
                            id="weight"
                            required
                            type="number"
                            value={weight.value}
                            onChange={this.handleWeightChange}
                            label="Peso (Kg)"
                            error={weight.error}
                          />
                          {weight.error ? (
                            <Error>{weight.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Input
                            id="storageStock"
                            required
                            type="number"
                            value={storageStock.value}
                            onChange={this.handleStorageStockChange}
                            error={storageStock.error}
                            label="Stock bodega"
                          />
                          {storageStock.error ? (
                            <Error>{storageStock.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column md={4}>
                          <Input
                            id="localStock"
                            required
                            type="number"
                            value={localStock.value}
                            onChange={this.handleLocalStockChange}
                            error={localStock.error}
                            label="Stock local"
                          />
                          {localStock.error ? (
                            <Error>{localStock.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Input
                            id="arriveInDays"
                            required
                            type="number"
                            value={arriveInDays.value}
                            onChange={this.handleArriveInDaysChange}
                            label="Días de entrega"
                            error={arriveInDays.error}
                          />
                          {arriveInDays.error ? (
                            <Error>{arriveInDays.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Select
                            id="status"
                            label="Estado"
                            placeholder="Seleccione un estado"
                            required
                            options={availableStatuses}
                            value={status}
                            onChange={this.handleStatusChange}
                            backspaceRemovesValue={false}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Paper>
                  </FormSection>

                  <FormSection title={'Agregar Meta Data'}>
                    <Grid.Row>
                      {metaDataArray.map((data, index) => {
                        return (
                          <Grid.Column md={4} key={index}>
                            <Paper>
                              <MetadataGroup
                                index={index}
                                metadata={data}
                                onKeyChange={this.handleKeyMetada}
                                onValueChange={this.handleValueMetada}
                                onCloseClick={this.removeMetada}
                              />
                            </Paper>
                            <Spacer />
                          </Grid.Column>
                        )
                      })}
                      <Grid.Column md={4}>
                        <Paper>
                          <AddFeatureWrapper>
                            <IconButton
                              color="primary"
                              icon={'plus'}
                              onClick={this.addItemToMetaData}
                            />
                            <AddFeatureText>Agregar nuevo item</AddFeatureText>
                          </AddFeatureWrapper>
                        </Paper>
                        <Spacer />
                      </Grid.Column>
                    </Grid.Row>
                  </FormSection>

                  <FormSection title={'Agregar imágenes y videos'}>
                    <Grid.Row>
                      <Grid.Column>
                        <Paper>
                          <AssetSelector
                            youtubeSupport
                            dropZoneSupport
                            colorButton="secondary"
                            textButton="Seleccionar archivos"
                            accept="image/gif,image/jpeg"
                            assets={assets}
                            onChange={this.handleAssetsChange}
                          />
                        </Paper>
                      </Grid.Column>
                    </Grid.Row>
                  </FormSection>

                  <ThemeContext.Consumer>
                    {notify => (
                      <Mutation
                        mutation={EDIT_VARIATION_MUTATION}
                        onCompleted={() => {
                          this.props.onUpdated()
                          notify &&
                            notify.onSetNotification &&
                            notify.onSetNotification({
                              type: 'ok',
                              message: 'Variación guardada correctamente',
                            })
                        }}
                        onError={() => {
                          openModal('ALERT', {
                            header: {
                              title: 'ALERTA',
                            },
                            description:
                              'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                            type: 'fail',
                          })
                        }}
                      >
                        {(editVariation, { loading, error }) => (
                          <Mutation
                            mutation={DELETE_FILE}
                            onError={() => {
                              openModal('ALERT', {
                                header: {
                                  title: 'ALERTA',
                                },
                                description:
                                  'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                type: 'fail',
                              })
                            }}
                          >
                            {(deleteFile, { loading: loadingDelete }) => (
                              <Mutation
                                mutation={UPLOAD_VARIATION_FILE}
                                onError={() => {
                                  openModal('ALERT', {
                                    header: {
                                      title: 'ALERTA',
                                    },
                                    description:
                                      'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                    type: 'fail',
                                  })
                                }}
                              >
                                {(
                                  uploadVariationFile,
                                  { loading: loadingUpdate }
                                ) => (
                                  <ActionWrapper>
                                    <CancelButton
                                      color="secondary"
                                      to="variations"
                                      alone
                                    >
                                      Cancelar
                                    </CancelButton>
                                    <Spacer />
                                    <Button
                                      color="primary"
                                      disabled={
                                        loading ||
                                        loadingDelete ||
                                        loadingUpdate
                                      }
                                      onClick={async () => {
                                        this.validateMetadata()
                                        let nextError = this.scrollToError()
                                        let errorStock = this.validateStock()
                                        let errorRegularPrice = this.validateRegularPrice()
                                        const uploadedImages: Asset[] = []
                                        const videos: Asset[] = []
                                        let prevAssets: Asset[] = []

                                        if (nextError) {
                                          if (this.state.assetsStatus) {
                                            try {
                                              for (const deleteAsset of this
                                                .state.deleteAssets) {
                                                if (
                                                  deleteAsset.type !== 'video'
                                                ) {
                                                  const fileID = deleteAsset.url.substring(
                                                    deleteAsset.url.lastIndexOf(
                                                      '/'
                                                    ) + 1
                                                  )
                                                  await deleteFile({
                                                    variables: {
                                                      id: fileID,
                                                    },
                                                  })
                                                }
                                              }
                                            } catch (error) {
                                              // TODO: Delete all uploaded files since one of them failed
                                              // tslint:disable-next-line:no-console
                                              console.log(
                                                'error deleting file',
                                                error
                                              )
                                              return
                                            }

                                            try {
                                              let index = 0
                                              for (const asset of assets) {
                                                index++

                                                if (asset.type === 'video') {
                                                  videos.push({
                                                    id: asset.id,
                                                    url: asset.url,
                                                    type: 'video',
                                                    order: index,
                                                  })
                                                } else {
                                                  if (asset.file != null) {
                                                    const response = await uploadVariationFile(
                                                      {
                                                        variables: {
                                                          file: asset.file,
                                                        },
                                                      }
                                                    )

                                                    if (response) {
                                                      const {
                                                        url,
                                                      } = response.data.uploadVariationFile

                                                      uploadedImages.push({
                                                        id: '',
                                                        url,
                                                        type: 'image',
                                                        order: index,
                                                      })
                                                    }
                                                  } else {
                                                    uploadedImages.push({
                                                      id: asset.id,
                                                      url: asset.url,
                                                      type: asset.type,
                                                      order: index,
                                                    })
                                                  }
                                                }
                                              }

                                              // deleteFile
                                              prevAssets = [
                                                ...uploadedImages,
                                                ...videos,
                                              ].map((asset: Asset) => ({
                                                url: asset.url,
                                                type: asset.type,
                                                order: asset.order,
                                              }))
                                            } catch (error) {
                                              // TODO: Delete all uploaded files since one of them failed
                                              // tslint:disable-next-line:no-console
                                              console.log(
                                                'error uploading file',
                                                error
                                              )
                                              return
                                            }
                                          } else {
                                            prevAssets = assets
                                          }
                                          nextError &&
                                            !errorRegularPrice &&
                                            !errorStock &&
                                            editVariation({
                                              variables: {
                                                input: {
                                                  productId:
                                                    product && product.id,
                                                  arriveInDays: Number(
                                                    arriveInDays.value
                                                  ),
                                                  courierCost: Number(
                                                    courierCost.value
                                                  ),
                                                  courierTaxes: Number(
                                                    courierTaxes.value
                                                  ),
                                                  height: Number(height.value),
                                                  inventory: {
                                                    localStock: Number(
                                                      localStock.value
                                                    ),
                                                    storageStock: Number(
                                                      storageStock.value
                                                    ),
                                                  },
                                                  length: Number(Length.value),
                                                  mileCost: Number(mileCost),
                                                  taxes: Number(taxes.value),
                                                  price: this.pricetotal(
                                                    supplierCost.value,
                                                    supplierDiscount.value,
                                                    profit.value,
                                                    courierCost.value,
                                                    taxes.value
                                                  ),
                                                  profit: Number(profit.value),
                                                  reference: reference.value,
                                                  status:
                                                    status && status['value'],
                                                  supplierCost: Number(
                                                    supplierCost.value
                                                  ),
                                                  supplierDiscount: Number(
                                                    supplierDiscount.value
                                                  ),
                                                  supplierSpecialTaxes: Number(
                                                    supplierSpecialTaxes.value
                                                  ),
                                                  supplierTaxes: Number(
                                                    supplierTaxes.value
                                                  ),
                                                  regularPrice: Number(
                                                    regularPrice.value
                                                  ),
                                                  refundPrice: Number(
                                                    refundPrice.value
                                                  ),
                                                  cardProfit: Number(
                                                    cardProfit.value
                                                  ),
                                                  weight: Number(weight.value),
                                                  width: Number(width.value),
                                                  variationId: id,
                                                  assets: prevAssets,
                                                  metaData: metaData,
                                                },
                                              },
                                            })
                                        }
                                      }}
                                    >
                                      {loading
                                        ? 'Guardando cambios...'
                                        : 'Guardar cambios'}
                                    </Button>
                                  </ActionWrapper>
                                )}
                              </Mutation>
                            )}
                          </Mutation>
                        )}
                      </Mutation>
                    )}
                  </ThemeContext.Consumer>
                </React.Fragment>
              )}
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
}

export default EditVariationForm
