import React from 'react'
import ReactSelect, {
  Creatable as ReactCreatableSelect,
  Async as ReactAsyncSelect,
} from 'react-select'
import { Props as ReactSelectProps } from 'react-select/lib/Select'
import { Props as ReactCreatableSelectProps } from 'react-select/lib/Creatable'
import { Props as ReactAsyncSelectProps } from 'react-select/lib/Async'
import 'react-datepicker/dist/react-datepicker.css'

import {
  StyledInput,
  StyledTextArea,
  selectStyles,
  RequiredMark,
  Tip,
  StyledTooltip,
  // HelpIcon,
  InputIcon,
  DropdownIcon,
  StyledLabel,
  StyledError,
  LabelWrapper,
  InputSpan,
  StyledCheckBoxWrapper,
  StyledCheckBox,
  HiddenCheckbox,
  StyledDatePicker,
  DatePickerContainer,
  CheckIcon,
  HiddenRadio,
  StyledRadio,
  StyledToggle,
  RadioIcon,
  SwitchCheckBox,
  SwitchLabel,
} from './style'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  help?: string
  error?: boolean
  icon?: string
  toggle?: boolean
  round?: boolean
  noShowRequired?: boolean
}

export const Input = ({
  required,
  noShowRequired,
  help,
  error,
  icon,
  label,
  ref,
  ...props
}: InputProps) => (
  <StyledLabel>
    {(help || label || required) &&
      !noShowRequired && (
        <LabelWrapper>
          {help && <Tooltip message={help} />}
          {label && <span>{label}</span>}
          {required && <RequiredMark>*</RequiredMark>}
        </LabelWrapper>
      )}
    <StyledInput {...props} error={error} required={required} icon={icon} />
    {icon && (
      <InputSpan>
        <InputIcon name={icon} />
      </InputSpan>
    )}
  </StyledLabel>
)

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  error?: boolean
}

export const TextArea = ({
  label,
  required,
  ref,
  error,
  ...props
}: TextAreaProps) => (
  <StyledLabel>
    {(label || required) && (
      <LabelWrapper>
        {label && <span>{label}</span>}
        {required && <RequiredMark>*</RequiredMark>}
      </LabelWrapper>
    )}
    <StyledTextArea {...props} error={error} />
  </StyledLabel>
)

interface SelectProps
  extends ReactSelectProps<{ [key: string]: any } | string | number> {
  error?: boolean
  required?: boolean
  label?: string
}

export const Select = ({ label, required, error, ...props }: SelectProps) => (
  <StyledLabel>
    {(label || required) && (
      <LabelWrapper>
        {label && <span>{label}</span>}
        {required && <RequiredMark>*</RequiredMark>}
      </LabelWrapper>
    )}
    <ReactSelect
      {...props}
      styles={selectStyles(error)}
      components={{
        DropdownIndicator: () => <DropdownIcon name="down-dir" error={error} />,
        IndicatorSeparator: null,
      }}
      noOptionsMessage={() => 'No existen resultados'}
    />
  </StyledLabel>
)

interface CreatableSelectProps
  extends ReactCreatableSelectProps<{ [key: string]: any }> {
  required?: boolean
  disabled?: boolean
  label?: string
  error?: boolean
  noOptionsMessage?: () => string
}

export const CreatableSelect = ({
  label,
  required,
  disabled,
  error,
  noOptionsMessage,
  ...props
}: CreatableSelectProps) => (
  <StyledLabel>
    {(label || required) && (
      <LabelWrapper>
        {label && <span>{label}</span>}
        {required && <RequiredMark>*</RequiredMark>}
      </LabelWrapper>
    )}
    <ReactCreatableSelect
      {...props}
      isDisabled={disabled}
      styles={selectStyles(error)}
      components={{
        DropdownIndicator: () => <DropdownIcon name="down-dir" error={error} />,
        IndicatorSeparator: null,
      }}
      noOptionsMessage={noOptionsMessage}
    />
  </StyledLabel>
)

interface AsyncSelectProps
  extends ReactAsyncSelectProps<{ [key: string]: any }> {
  required?: boolean
  disabled?: boolean
  label?: string
  error?: boolean
}

export const AsyncSelect = ({
  label,
  required,
  disabled,
  error,
  ...props
}: AsyncSelectProps) => (
  <StyledLabel>
    {(label || required) && (
      <LabelWrapper>
        {label && <span>{label}</span>}
        {required && <RequiredMark>*</RequiredMark>}
      </LabelWrapper>
    )}
    <ReactAsyncSelect
      {...props}
      styles={selectStyles(error, disabled)}
      isDisabled={disabled}
      components={{
        DropdownIndicator: () => <DropdownIcon name="down-dir" error={error} />,
        IndicatorSeparator: null,
      }}
      noOptionsMessage={() => 'No existen resultados'}
    />
  </StyledLabel>
)

interface TooltipProps {
  id?: string
  message?: string
  children?: React.ReactNode
}

export const Tooltip = ({ id, message, children }: TooltipProps) => (
  <span>
    <Tip data-tip data-for={id}>
      {/* TODO: Check how to add help icon  */}
      {children}
    </Tip>

    <StyledTooltip id={id} type="error">
      <span>{message}</span>
    </StyledTooltip>
  </span>
)

export const CheckBox = ({
  id,
  label,
  value,
  onChange,
  checked,
  disabled,
  toggle,
  round,
}: InputProps) => {
  return (
    <StyledLabel>
      <StyledCheckBoxWrapper>
        <HiddenCheckbox
          value={value}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        {toggle ? (
          <StyledToggle
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            round={true}
          />
        ) : (
          <StyledCheckBox
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            round={round}
          >
            <CheckIcon name="ok" />
          </StyledCheckBox>
        )}

        <LabelWrapper>{label && <span>{label}</span>}</LabelWrapper>
      </StyledCheckBoxWrapper>
    </StyledLabel>
  )
}

export const RadioOption = ({
  id,
  label,
  onChange,
  value,
  checked,
  disabled,
}: InputProps) => {
  return (
    <StyledLabel>
      <StyledCheckBoxWrapper>
        <HiddenRadio
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />

        <StyledRadio onChange={onChange} disabled={disabled}>
          {checked ? <RadioIcon /> : ''}
        </StyledRadio>

        {<LabelWrapper>{label && <span>{label}</span>}</LabelWrapper>}
      </StyledCheckBoxWrapper>
    </StyledLabel>
  )
}

interface ErrorProps {
  children: React.ReactNode
}

export const Error = (props: ErrorProps) => (
  <StyledError>{props.children}</StyledError>
)

interface DatePickerProps {
  error?: boolean
  required?: boolean
  disabled?: boolean
  dateFormat?: string
  label: string
  date: Date
  showTimeSelect?: boolean
  onChange: (date: Date) => void
}

export const DatePicker = ({
  required,
  disabled,
  error,
  label,
  date,
  onChange,
  dateFormat,
  showTimeSelect,
  ...props
}: DatePickerProps) => (
  <StyledLabel>
    {(label || required) && (
      <LabelWrapper>
        {label && <span>{label}</span>}
        {required && <RequiredMark>*</RequiredMark>}
      </LabelWrapper>
    )}
    <DatePickerContainer>
      <StyledDatePicker
        selected={date}
        onChange={onChange}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
      />
      <InputSpan>
        <InputIcon name="calendar" />
      </InputSpan>
    </DatePickerContainer>
  </StyledLabel>
)

export const Switch = ({ id, onChange, checked, disabled }: InputProps) => {
  return (
    <>
      <SwitchCheckBox
        id={id}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <SwitchLabel htmlFor={id}>
        <span />
      </SwitchLabel>
    </>
  )
}
