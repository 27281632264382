import React from 'react'

import MenuItem from './MenuItem'

import { MenuGroup } from './style'
import ThemeContext from '../../../../context/ThemeContext'

class Menu extends React.Component<any, any> {
  render() {
    return (
      <ThemeContext.Consumer>
        {context => {
          return context.login.user == 'pqrs-callcenter' ? (
            <MenuGroup>
              <MenuItem icon="icono_pqrs" title="PQRS" to="pqrs" />
              <MenuItem
                icon="logistica_menu"
                title="Logística"
                items={[
                  {
                    url: '/orders',
                    label: 'Órdenes',
                  },
                  {
                    url: '/pending-orders',
                    label: 'Nuevos canjes',
                  },
                  {
                    url: '/process-orders',
                    label: 'Procesar órdenes',
                  },
                  {
                    url: '/purchase-orders',
                    label: 'Órdenes de compra',
                  },
                  {
                    url: '/fulfillment',
                    label: 'Órdenes de recogida',
                  },
                  {
                    url: '/coupons',
                    label: 'Cupones',
                  },
                ]}
              />
              <MenuItem
                icon="reportes_menu"
                title="Reportes"
                items={[
                  {
                    url: '/sales-report',
                    label: 'Reporte de ventas',
                  },
                  {
                    url: '/orders-report',
                    label: 'Reporte de ventas sin logística',
                  },
                ]}
              />
              <MenuItem icon="logout" title="Logout" to="logout" />
            </MenuGroup>
          ) : context.login.user == 'superadmin-all-system' ? (
            <MenuGroup>
              <MenuItem icon="home_menu" title="Inicio" to="#" />
              <MenuItem
                icon="home_menu"
                title="Administración"
                items={[
                  {
                    url: '#',
                    label: 'Usuario',
                  },
                  {
                    url: '/approve',
                    label: 'Aprobaciones',
                  },
                  {
                    url: '#',
                    label: 'Programas',
                  },
                ]}
              />
              <MenuItem icon="socios_menu" title="Socios" to="#partners" />
              <MenuItem icon="millas_menu" title="Millas" to="#miles" />
              <MenuItem
                icon="productos_menu"
                title="Productos"
                items={[
                  {
                    url: '/brands',
                    label: 'Marcas',
                  },
                  {
                    url: '/categories',
                    label: 'Categorías',
                  },
                  {
                    url: '/products',
                    label: 'Productos',
                  },
                  {
                    url: '/variations',
                    label: 'Variaciones',
                  },
                  {
                    url: '/products-bulk-import',
                    label: 'Carga masiva de nuevos productos',
                  },
                  {
                    url: '/products-update-bulk-import',
                    label: 'Actualización masiva de productos',
                  },
                ]}
              />
              <MenuItem
                icon="logistica_menu"
                title="Logística"
                items={[
                  {
                    url: '/suppliers',
                    label: 'Proveedores',
                  },
                  {
                    url: '/orders',
                    label: 'Órdenes',
                  },
                  {
                    url: '/pending-orders',
                    label: 'Nuevos canjes',
                  },
                  {
                    url: '/process-orders',
                    label: 'Procesar órdenes',
                  },
                  {
                    url: '/purchase-orders',
                    label: 'Órdenes de compra',
                  },
                  {
                    url: '/fulfillment',
                    label: 'Órdenes de recogida',
                  },
                  {
                    url: '/coupons',
                    label: 'Cupones',
                  },
                ]}
              />
              <MenuItem
                icon=""
                title="Brokerage"
                items={[
                  {
                    url: '/commerces',
                    label: 'Comercios',
                  },
                ]}
              />
              <MenuItem icon="icono_pqrs" title="PQRS" to="pqrs" />
              <MenuItem
                icon="reportes_menu"
                title="Reportes"
                items={[
                  {
                    url: '/sales-report',
                    label: 'Reporte de ventas',
                  },
                  {
                    url: '/orders-report',
                    label: 'Reporte de ventas sin logística',
                  },
                ]}
              />
              <MenuItem icon="icono_pqrs" title="Promos" to="promo" />
              <MenuItem
                icon="icono_pqrs"
                title="Tipo promos"
                to="promo-types"
              />
              <MenuItem icon="logout" title="Logout" to="logout" />
            </MenuGroup>
          ) : (
            <MenuGroup>
              <MenuItem icon="logout" title="Logout" to="logout" />
            </MenuGroup>
          )
        }}
      </ThemeContext.Consumer>
    )
  }
}

export default Menu
