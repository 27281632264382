import React from 'react'
import {
  /*Error,*/ CheckBox,
  Select,
  Switch,
} from '../../../../components/FormElements'
import {
  CloseButton,
  CloseIcon,
  ScheduleData,
  ScheduleLabel,
  ScheduleDates,
  ScheduleDayLabel,
  SwitchDummy,
} from '../style'
import { SUPPLIER_SCHEDULE } from '../../../../config'
import { ValueType } from 'react-select/lib/types'

interface Props {
  index: number
  error?: string
  data: any
  scheduleType: boolean
  value?: string
  oldOptions?: string[]
  disabled?: boolean
  required?: boolean
  isEdit: boolean

  onCloseClick: (featureIndex: number) => void
  onChecked: (valueCheck: boolean) => void
  onSelectChange: (
    status: ValueType<string | number | { [key: string]: any }>,
    index: any,
    type: string
  ) => void
  onSwitchChange: (index: number, value: boolean) => void
}

const ScheduleGroup = ({
  index,
  data,
  scheduleType,
  isEdit,

  onCloseClick,
  onChecked,
  onSelectChange,
  onSwitchChange,
}: Props) => (
  <React.Fragment>
    <CloseButton
      onClick={() => {
        onCloseClick && onCloseClick(index)
      }}
    >
      <CloseIcon name="cancel-circled" />
    </CloseButton>
    <ScheduleData>
      <ScheduleLabel>Tipo de horarios</ScheduleLabel>
      <CheckBox
        icon={'circle'}
        round={true}
        label="Horarios unificados"
        checked={scheduleType}
        onChange={() => onChecked(!scheduleType)}
      />
      <CheckBox
        icon={'circle'}
        round={true}
        label="Horarios dinámicos"
        checked={!scheduleType}
        onChange={() => onChecked(!scheduleType)}
      />
    </ScheduleData>
    {data.map((v: any, i: any) => {
      const data = isEdit ? v.value.value : v.value.value
      return (
        <ScheduleDates key={`schedule-${i}`}>
          <ScheduleDayLabel key={i}> {data.day} </ScheduleDayLabel>
          {!scheduleType ? (
            <Switch
              id={`active-${i}`}
              key={`active-${i}`}
              checked={data.active}
              onChange={() => onSwitchChange(i, !data.active)}
            />
          ) : (
            <>
              <SwitchDummy />
              <SwitchDummy />
            </>
          )}

          <Select
            id={`to-${i}`}
            key={`to-${i}`}
            label="Desde"
            placeholder="Seleccione un estado"
            options={SUPPLIER_SCHEDULE}
            value={data.to}
            onChange={(
              option: ValueType<string | number | { [key: string]: any }>
            ) => onSelectChange(option, i, 'to')}
            backspaceRemovesValue={false}
            isDisabled={!data.active}
          />
          <Select
            id={`from-${i}`}
            key={`from-${i}`}
            label="Hasta"
            placeholder="Seleccione un estado"
            options={SUPPLIER_SCHEDULE}
            value={data.from}
            onChange={e => onSelectChange(e, i, 'from')}
            backspaceRemovesValue={false}
            isDisabled={!data.active}
          />
        </ScheduleDates>
      )
    })}
  </React.Fragment>
)

export default ScheduleGroup
