import React from 'react'
import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import CouponTable from './components/CouponTable'
import CouponDetailsTable from './components/CouponDetailsTable'

import { PageTopSection } from './style'

export const CouponList = () => {
  return (
    <PageWrapper title="Lista de Cupones">
      <Paper>
        <CouponTable />
      </Paper>
      <PageTopSection />
    </PageWrapper>
  )
}

export const CouponTransactionList = () => {
  return (
    <PageWrapper title={'Detalles del cupon'}>
      <Paper>
        <CouponDetailsTable />
      </Paper>
      <PageTopSection />
    </PageWrapper>
  )
}
