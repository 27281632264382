import React from 'react'
import { ValueType } from 'react-select/lib/types'
import { AppConsumer, IState } from '../../context/AppContext'
//import queryString from 'query-string'

import {
  Container,
  Section,
  CursorSection,
  Table,
  TableHead,
  TableHeaderRow,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  MobileHeader,
  ResultsPerPage,
  ButtonsGroup,
  LoadingOverlay,
  FilterSection,
  FilterTitle,
  Filter,
} from './style'
import * as Grid from '../Grid'
import { Select } from '../FormElements'
import Pagination from './components/Pagination'
import PaginationInfo from './components/PaginationInfo'
import SortingIndicator from './components/SortingIndicator'
import SearchBar from './components/SearchBar'
import CursorPagination from './components/CursorPagination'
import { Button } from '../Buttons'

export interface ColumnType {
  header: string | any
  key: string
  width?: number
  sortable?: boolean
  // All Renderers
  Cell?: (data: Record<string, any>, index: number) => React.ReactNode
}

type SortDirectionType = 'asc' | 'desc'

type FilterOptionType = Record<string, any>

interface FilterType {
  key: string
  options: FilterOptionType[]
  label: string
}

interface Props {
  columns: ColumnType[]
  data: Array<Record<string, any>> | null
  indexKey: string
  totalItemsCount?: number
  placeholderSearchBar?: string
  page?: number
  pageSize: number
  pageSizeOptions: number[]
  sortBy?: string
  filterOptions?: Record<string, any>
  searchText?: string

  filters?: FilterType[]

  onSort: (sortBy: string | undefined) => void

  onPageChange?: (pageNumber: number) => void
  onPageSizeChange: (pageSize: number) => void

  onSearch?: (searchText: string | undefined) => void

  onFetch?: (filters: any | undefined) => void

  onAfter?: (after: string | undefined) => void
  onBefore?: (before: string | undefined) => void
  after?: string
  before?: string

  filtersOpened?: boolean
  toggleFilterSection?: () => void
  filterSelected?: FilterOptionType | null
  onFilterSelected?: (
    index: number,
    filterSelected: FilterOptionType | null | undefined
  ) => void
  supplierFromUrl?: string

  loading: boolean

  button?: {
    onClick: () => void
    textButton: string
  }

  state: IState
}

interface FilterStateType extends FilterType {
  selected: FilterOptionType | null
}

interface DataTableState {
  searchText: string | null
  sortKey: string | null
  sortDirection: SortDirectionType
  activePage: number
  pageSize: number
  filters: FilterStateType[] | undefined
  shadowFilters: null
}

interface PageSizeOptionsType {
  label: string
  value: number
}

class DataTable extends React.Component<Props, DataTableState> {
  constructor(props: Props) {
    super(props)

    const { filters } = this.props
    this.state = {
      searchText: '',
      sortKey: '',
      sortDirection: 'asc',
      activePage: 0,
      pageSize: 0,
      filters: this.parseFilters(filters),
      shadowFilters: null,
    }
  }
  componentDidMount() {
    //if (this.props.state.filters.length === 0) {
    this.props.state.handleState(this.props.filters)
    //}
  }
  parseSortBy = (
    sortBy: string | undefined
  ): { sortDirection: SortDirectionType; sortKey: string | null } => {
    if (!sortBy) {
      return {
        sortDirection: 'asc',
        sortKey: null,
      }
    }

    if (sortBy.startsWith('-')) {
      return { sortDirection: 'desc', sortKey: sortBy.slice(1) }
    }
    return {
      sortDirection: 'asc',
      sortKey: sortBy,
    }
  }

  parseFilters = (filters: FilterType[] | undefined) => {
    //const values = queryString.parse(window.location.search)
    if (!filters) {
      return undefined
    }

    return filters.map(filter => {
      const filtersState: FilterStateType = {
        ...filter,
        selected: null,
      }

      return filtersState
    })
  }

  formatParams = () => {
    const params: any = {}

    const { filters } = this.state

    if (filters) {
      const selectedFilters: Record<string, any> = {}
      filters.forEach(filter => {
        if (filter.selected) {
          selectedFilters[filter.key] = filter.selected
        }
      })
      params.filters = selectedFilters
    }

    return params
  }

  onColumnHeaderClick = (sortKey: string, sortDirection: SortDirectionType) => {
    const { onSort } = this.props

    const sortBy = `${sortDirection === 'desc' ? '-' : ''}${sortKey}`

    onSort && onSort(sortBy)
  }

  handleFilterChange = (index: number, filterValue: any) => {
    const filters = this.props.state.filters
    if (!filters) {
      return
    }
    const shallowFilters = filters
    shallowFilters[index].selected = filterValue
    this.props.state.handleState(shallowFilters)
    this.setState({ filters, activePage: 1 }, () => {
      const formatParams = this.formatParams()
      this.props.onFetch && this.props.onFetch(formatParams)
    })
  }

  render() {
    const {
      columns,
      data,
      indexKey,
      totalItemsCount,
      loading,
      sortBy,
      page,
      pageSize,
      pageSizeOptions,
      searchText,
      onPageChange,
      onPageSizeChange,
      onSearch,
      onAfter,
      onBefore,
      after,
      before,
      filtersOpened,
      toggleFilterSection,
      button,
      // filters,
      filterSelected,
      supplierFromUrl,
      placeholderSearchBar,
    } = this.props

    //const { filters } = this.state
    const filters = this.props.state.filters

    const { sortKey, sortDirection } = this.parseSortBy(sortBy)

    const pageSizeSelectOptions = pageSizeOptions.map(option => ({
      value: option,
      label: `${option}`,
    }))

    return (
      <Container>
        {onSearch && (
          <Grid.Row>
            <Grid.Column lg={12}>
              <SearchBar
                defaultSearchText={searchText}
                onSearch={onSearch}
                placeholder={placeholderSearchBar}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Section>
          {pageSizeOptions.length > 0 && (
            <ResultsPerPage>
              <Select
                placeholder="Seleccione una opción"
                options={pageSizeSelectOptions}
                value={{ label: `${pageSize}`, value: pageSize }}
                onChange={(
                  pageSizeOption: ValueType<
                    string | number | { [key: string]: any }
                  >
                ) => {
                  onPageSizeChange(pageSizeOption && pageSizeOption['value'])
                }}
                getOptionLabel={(option: PageSizeOptionsType) =>
                  `${option.label}`
                }
                getOptionValue={(option: PageSizeOptionsType) =>
                  `${option.value}`
                }
                backspaceRemovesValue={false}
              />
              <span>Por Página</span>
            </ResultsPerPage>
          )}
          <ButtonsGroup>
            {filters && (
              <Button
                icon={'sliders'}
                color={'tertiary'}
                onClick={() => toggleFilterSection && toggleFilterSection()}
              >
                Ver Filtros
              </Button>
            )}
            {button && (
              <Button color={'primary'} onClick={button.onClick}>
                {button.textButton}
              </Button>
            )}
          </ButtonsGroup>
        </Section>
        {filtersOpened &&
          filters && (
            <FilterSection>
              <FilterTitle>Filtrar por</FilterTitle>
              <Grid.ContainerFluid>
                <Grid.Row>
                  {filters.map((filter: any, index: number) => {
                    let test: any = null
                    if (filterSelected && filterSelected.index === index) {
                      test = filterSelected.selected
                    }

                    return (
                      <Grid.Column md={4} key={index}>
                        <Filter>
                          <Select
                            key={filter.key}
                            label={filter.label}
                            options={filter.options}
                            value={
                              filter.selected
                                ? filter.selected
                                : test
                                  ? test
                                  : //: filter.key === 'supplier' &&
                                    filter.options.find(
                                      (option: any) =>
                                        option.value === supplierFromUrl
                                    )
                            }
                            placeholder={'Seleccione'}
                            onChange={value =>
                              this.handleFilterChange(index, value)
                            }
                            backspaceRemovesValue={false}
                          />
                        </Filter>
                      </Grid.Column>
                    )
                  })}
                </Grid.Row>
              </Grid.ContainerFluid>
            </FilterSection>
          )}

        <Section>
          <Table>
            <TableHead>
              <TableHeaderRow>
                {columns.map((column, index) => {
                  const tableHeaderProps: any = {
                    key: column.key,
                    index,
                  }

                  let isColumnSortable = true
                  if (column.sortable === undefined) {
                    isColumnSortable = true
                  } else {
                    isColumnSortable = column.sortable
                  }

                  if (
                    isColumnSortable &&
                    sortKey !== undefined &&
                    sortDirection !== undefined
                  ) {
                    tableHeaderProps.onClick = () => {
                      let newSortDirection: SortDirectionType = 'asc'
                      if (sortKey === column.key) {
                        if (sortDirection === 'asc') {
                          newSortDirection = 'desc'
                        } else {
                          newSortDirection = 'asc'
                        }
                      }

                      this.onColumnHeaderClick(column.key, newSortDirection)
                    }
                  }

                  return (
                    // tslint:disable-next-line:jsx-key
                    <TableHeader {...tableHeaderProps} width={column.width}>
                      {column.header}
                      {isColumnSortable && (
                        <SortingIndicator
                          direction={
                            sortKey !== column.key ? null : sortDirection
                          }
                        />
                      )}
                    </TableHeader>
                  )
                })}
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((record, recordIndex) => (
                  <TableRow
                    key={record[indexKey]}
                    index={recordIndex}
                    highlightColumnByStatus={
                      typeof record['status'] !== 'undefined'
                        ? record['status']
                        : ''
                    }
                  >
                    {columns.map((column, columnIndex) => {
                      return (
                        <TableCell key={column.key} index={columnIndex}>
                          <MobileHeader>{column.header}:</MobileHeader>
                          {column.Cell
                            ? column.Cell(record, recordIndex)
                            : record[column.key]}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Section>
        {totalItemsCount &&
          page &&
          onPageChange && (
            <Section>
              <PaginationInfo
                totalItemsCount={totalItemsCount}
                itemsCountPerPage={pageSize}
                activePage={page}
              />
              <Pagination
                totalItemsCount={totalItemsCount}
                itemsCountPerPage={pageSize}
                activePage={page}
                onChange={onPageChange}
              />
            </Section>
          )}
        {onAfter &&
          onBefore && (
            <CursorSection>
              <CursorPagination
                onAfter={onAfter}
                onBefore={onBefore}
                after={after}
                before={before}
              />
            </CursorSection>
          )}
        {loading && <LoadingOverlay />}
      </Container>
    )
  }
}

export default (props: any) => (
  <AppConsumer>
    {state => {
      return <DataTable {...props} state={state} />
    }}
  </AppConsumer>
)
