import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'

import { AsyncSelect } from '../../FormElements'

import Loading from '../../Loader'
import { Spacer } from '../../Globals'

const GET_SUPPLIERS_QUERY = gql`
  query getSuppliersQuery(
    $options: SuppliersOptions!
    $filters: SuppliersFilters!
  ) {
    suppliers(options: $options, filters: $filters) {
      nodes {
        id
        businessName
      }
    }
  }
`
type Supplier = { [key: string]: any }
export type SupplierOption = ValueType<{ [key: string]: any }>

interface Data {
  suppliers: {
    nodes: Supplier[]
  }
}

interface Props {
  value: SupplierOption | SupplierOption[] | null
  onChange: (supplier: SupplierOption) => void
  isMulti?: boolean
  isRequired: boolean
  disabled?: boolean
  error?: boolean
}

const SupplierSelector = ({
  value,
  onChange,
  isMulti,
  isRequired,
  disabled,
  error,
}: Props) => (
  <Query
    query={GET_SUPPLIERS_QUERY}
    fetchPolicy="network-only"
    variables={{ options: { limit: 10 }, filters: {} }}
  >
    {({ loading, error: errorQuery, data, client }: QueryResult<Data, any>) => {
      if (loading) {
        return (
          <div id={'supplier'}>
            <Spacer />
            <Loading />
          </div>
        )
      }
      if (errorQuery || !data) {
        return (
          <p>
            Error cargando proveedores. <br />
            Por favor recargue la página.
          </p>
        )
      }

      return (
        <AsyncSelect
          id="supplierSelect"
          label="Proveedor"
          placeholder="Seleccione un proveedor"
          required={isRequired}
          disabled={disabled}
          inputId={'supplier'}
          value={value}
          isMulti={isMulti ? isMulti : false}
          onChange={onChange}
          getOptionLabel={({ businessName }: Supplier) => businessName}
          getOptionValue={({ id }: Supplier) => id}
          backspaceRemovesValue={false}
          defaultOptions={data.suppliers.nodes}
          cacheOptions
          error={error}
          loadOptions={async inputValue => {
            const { data } = await client.query<Data>({
              query: GET_SUPPLIERS_QUERY,
              variables: {
                options: { limit: 10 },
                filters: { s: inputValue },
              },
            })

            if (!data) {
              return []
            }

            return data.suppliers.nodes
          }}
        />
      )
    }}
  </Query>
)

export default SupplierSelector
