import React from 'react'
import {
  TableContainer,
  Container,
  ColumnDescription,
  ColumnTitle,
  DescriptionContainer,
} from './style'
import { Spacer } from '../../../../../components/Globals'

interface Props {
  purchase: any
}

class PurchaseOrderActionsModal extends React.Component<Props> {
  render() {
    const { purchase } = this.props

    return (
      <>
        <DescriptionContainer />
        <TableContainer>
          <Container>
            <ColumnTitle>Producto</ColumnTitle>
            <ColumnDescription>{purchase.productName}</ColumnDescription>
            <ColumnTitle>Referencia</ColumnTitle>
            <ColumnDescription>{purchase.reference}</ColumnDescription>
            <ColumnTitle>Costo milla</ColumnTitle>
            <ColumnDescription>0</ColumnDescription>
            <ColumnTitle>Largo</ColumnTitle>
            <ColumnDescription>{purchase.length}</ColumnDescription>
            <ColumnTitle>Alto</ColumnTitle>
            <ColumnDescription>{purchase.height}</ColumnDescription>
          </Container>
          <Container>
            <ColumnTitle>Características</ColumnTitle>
            {purchase &&
            purchase.lineItem &&
            purchase.lineItem.variation &&
            purchase.lineItem.variation.options ? (
              purchase.lineItem.variation.options.map(
                (option: any, index: any) => {
                  return (
                    <ColumnDescription key={index}>
                      {option.feature.name}: {option.name}
                    </ColumnDescription>
                  )
                }
              )
            ) : (
              <Spacer />
            )}
            <ColumnTitle>Estado</ColumnTitle>
            <ColumnDescription>Pagado</ColumnDescription>
            <ColumnTitle>Precio en Millas</ColumnTitle>
            <ColumnDescription>0</ColumnDescription>
            <ColumnTitle>Ancho</ColumnTitle>
            <ColumnDescription>{purchase.width}</ColumnDescription>
            <ColumnTitle>Alto</ColumnTitle>
            <ColumnDescription>{purchase.weight}</ColumnDescription>
          </Container>
        </TableContainer>
      </>
    )
  }
}

export default PurchaseOrderActionsModal
