import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import { STATUS_OPTIONS_SUPPLIERS } from '../../../../config'

import FormSection from '../../../../components/FormSection'
import * as Grid from '../../../../components/Grid'
import { IconButton } from '../../../../components/Buttons'
import Paper from '../../../../components/Paper'
import {
  Input,
  Select,
  Error,
  CheckBox,
  TextArea,
} from '../../../../components/FormElements'
import { Spacer } from '../../../../components/Globals'
import ContactGroup from './ContactGroup'
import AssetSelector, { Asset } from '../../../../components/AssetSelector'
import CourierSelector, { CourierOption } from '../components/CourierSelector'
import DriveInSchemaSelector, {
  DriveInSchemasOption,
} from '../../../../components/Selectors/DriveInSchemaSelector'
import Modal from '../../../../components/Modals/ModalRoot'
import MetadataGroup from '../components/MetadataGroup'
import ScheduleGroup from '../components/ScheduleGroup'

import {
  SaveButton,
  AlignRight,
  AddContactWrapper,
  AddContactText,
  AddFeatureWrapper,
  AddFeatureText,
  MapContainer,
  MapDetails,
} from '../style'

import ThemeContext from '../../../../context/ThemeContext'

import {
  validateField,
  InputElements,
  getElements,
  validateForm,
  Field,
  initialField,
  scrollTo,
} from '../../../../helpers/validations'
import Map from './Map'

export const EDIT_SUPPLIER_MUTATION = gql`
  mutation editSupplierMutation($input: EditSupplierInput!) {
    editSupplier(input: $input)
  }
`

export const UPLOAD_SUPPLIER_FILE = gql`
  mutation uploadSupplierFile($file: Upload!) {
    uploadSupplierFile(file: $file) {
      url
      fileName
    }
  }
`

export const DELETE_FILE = gql`
  mutation deleteFile($id: String) {
    deleteFile(id: $id)
  }
`

interface Metadata {
  key: Field
  value: Field
}

interface Schedule {
  id: number
  key: Field
  value: Field
}

// interface GpsCoverage {
//   length: Number
//   latitude: Number
// }

// interface GpsData {
//   key: any
//   value: any
// }

interface State {
  id: string
  legalName: Field
  businessName: Field
  city: Field
  ruc: Field
  phone1: Field
  phone2: Field
  website: Field
  type: Field
  personType: Field
  address: Field
  courier: Field
  deliverProducts: boolean
  tagProducts: boolean
  contacts: Array<{
    name: Field
    email: Field
  }>
  assets: Asset[]
  oldAssets: Asset[]
  deleteAssets: Asset[]
  createError: boolean
  assetsStatus: boolean
  inputs: InputElements[]
  metaData: any
  gpsCoverage: JSON
  showMap: boolean
  schemaCode: Field
  metaDataArray: Metadata[]
  schedule: JSON
  scheduleArray: Schedule[]
  scheduleType: boolean
  gpsCoverageArray: any[]
  slug: Field
  status: ValueType<string | number | { [key: string]: any }>
}

export interface Supplier {
  id: string
  legalName: string
  businessName: string | null
  city: string
  ruc: string
  phone1: string
  phone2: string | null
  website: string
  type: string
  personType: string
  address: string
  courier: CourierOption | null
  deliverProducts: boolean
  tagProducts: boolean
  contacts: Array<{
    id: string
    name: string
    email: string
  }>
  assets: Array<{
    id: string
    url: string
    order: number
    type: string
  }>
  metaData: JSON
  gpsCoverage: JSON
  slug: string
  status: string
  showMap: boolean
  schemaCode: DriveInSchemasOption
}

interface Props {
  supplier: Supplier
  onUpdated: () => void
}

const inputIds = [
  'personType',
  'type',
  'phone1',
  'ruc',
  'city',
  'businessName',
  'legalName',
]
const selectsRequired = ['personType', 'type']

class EditSupplierForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const {
      id,
      legalName,
      businessName,
      city,
      ruc,
      phone1,
      phone2,
      website,
      type,
      address,
      courier,
      deliverProducts,
      tagProducts,
      contacts,
      assets,
      metaData,
      gpsCoverage,
      schemaCode,
      slug,
      status,
    } = this.props.supplier

    const results = STATUS_OPTIONS_SUPPLIERS.filter(statusArray => {
      return statusArray.value === status
    })

    let valuesScheduleSort: any = []
    let initialData = {
      id: 1,
      key: { ...initialField, value: initialField },
      value: { ...initialField, value: initialField },
    }
    valuesScheduleSort = [
      initialData,
      initialData,
      initialData,
      initialData,
      initialData,
      initialData,
      initialData,
    ]

    const sortScheduleOptions = (scheduleData: any) => {
      let scheduleOptions = Object.entries(scheduleData['horarios']).map(
        items => {
          let index: number

          switch (items[0]) {
            case 'monday':
              index = 0
              break
            case 'tuesday':
              index = 1
              break
            case 'wednesday':
              index = 2
              break
            case 'thursday':
              index = 3
              break
            case 'friday':
              index = 4
              break
            case 'saturday':
              index = 5
              break
            case 'sunday':
              index = 6
              break
            default:
              index = 1
              isMondayToSunday = true
              valuesScheduleSort = []
          }

          valuesScheduleSort[index] = {
            key: { ...initialField, value: items[0] },
            value: { ...initialField, value: items[1] },
          }

          return valuesScheduleSort
        }
      )
      return scheduleOptions[0]
    }

    const getMetadata = (metaDataValue: any) => {
      let values = Object.keys(metaDataValue).map(item => {
        let data = { key: initialField, value: initialField }
        if (item != 'horarios') {
          data = {
            key: { ...initialField, value: item },
            value: { ...initialField, value: metaDataValue[item] },
          }
        }
        return data
      })

      values.forEach((item, index) => {
        if (item.key.value === '') {
          values.splice(-index, 1)
        }
      })

      return values
    }
    const statusSelected =
      results.length > 0
        ? results[0]
        : {
            value: 'inactive',
            label: 'Inactivo',
          }

    let displayMap = false

    if (courier) {
      if (courier['name'] && courier['name'] == 'DriveIn') {
        displayMap = true
      }
    }
    let isMondayToSunday = false

    this.state = {
      id,
      legalName: {
        ...initialField,
        value: legalName,
      },
      businessName: {
        ...initialField,
        value: businessName,
      },
      city: {
        ...initialField,
        value: city,
      },
      ruc: {
        ...initialField,
        value: ruc,
      },
      phone1: {
        ...initialField,
        value: phone1,
      },
      phone2: {
        ...initialField,
        value: phone2,
      },
      website: {
        ...initialField,
        value: website,
      },
      status: { value: statusSelected.value, label: statusSelected.label },
      type: {
        ...initialField,
        value:
          type && type['value'] === 'national'
            ? { label: 'Nacional', value: 'national' }
            : { label: 'Internacional', value: 'international' },
      },
      personType: {
        ...initialField,
        value:
          type === 'national'
            ? { label: 'Natural', value: 'natural' }
            : { label: 'Jurídica', value: 'juridical' },
      },

      address: {
        ...initialField,
        value: address,
      },
      courier: {
        ...initialField,
        value: courier,
      },
      deliverProducts,
      tagProducts,
      contacts: contacts.map(contact => {
        return {
          id: contact.id,
          name: { value: contact.name, error: false, errorText: '' },
          email: { value: contact.email, error: false, errorText: '' },
        }
      }),
      assets: assets.map(asset => ({
        id: asset.id,
        url: asset.url,
        type:
          asset.url.includes('.jpg') ||
          asset.url.includes('.png') ||
          asset.url.includes('.jpeg')
            ? 'image'
            : asset.url.substring(asset.url.lastIndexOf('.') + 1),
        order: asset.order,
      })),
      oldAssets: assets.map(asset => {
        return {
          id: asset.id,
          url: asset.url,
          type: asset.url.substring(asset.url.lastIndexOf('.') + 1),
          order: asset.order,
        }
      }),
      deleteAssets: [],
      createError: false,
      assetsStatus: false,
      inputs: [],
      metaData: metaData,
      metaDataArray: metaData ? getMetadata(metaData) : [],
      // metaData
      //   ? Object.keys(metaData).map(item => {
      //       let data = { key: initialField, value: initialField }
      //       if (item != 'horarios') {
      //         data = {
      //           key: { ...initialField, value: item },
      //           value: { ...initialField, value: metaData[item] },
      //         }
      //       }
      //       return data
      //     })
      //   : [],
      scheduleArray:
        metaData && metaData['horarios'] ? sortScheduleOptions(metaData) : [],
      schedule: metaData && metaData['horarios'] ? metaData['horarios'] : [],
      scheduleType: isMondayToSunday,
      slug: {
        ...initialField,
        value: slug,
      },
      gpsCoverage: gpsCoverage,
      gpsCoverageArray: gpsCoverage
        ? Object.keys(gpsCoverage).map(item => {
            return gpsCoverage[item]
          })
        : [],
      showMap: displayMap,
      schemaCode: {
        ...initialField,
        value: { code: schemaCode },
      },
    }
  }

  handleLegalNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const legalName = value

    const validations = validateField(legalName, [
      {
        name: 'shorterThan',
        value: 40,
      },
    ])

    if (validations.value) {
      this.setState({
        legalName: {
          value: legalName,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      legalName: {
        value: legalName,
        error: false,
        errorText: '',
      },
    })
  }

  handleBusinessNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const businessName = value

    const validations = validateField(businessName, [
      {
        name: 'shorterThan',
        value: 40,
      },
    ])

    if (validations.value) {
      this.setState({
        businessName: {
          value: businessName,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      businessName: {
        value: businessName,
        error: false,
        errorText: '',
      },
    })
  }

  handleCityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const city = value

    const validations = validateField(city, [
      {
        name: 'shorterThan',
        value: 40,
      },
    ])

    if (validations.value) {
      this.setState({
        city: {
          value: city,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      city: {
        value: city,
        error: false,
        errorText: '',
      },
    })
  }

  handleRUCChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const ruc = value

    const validations = validateField(ruc, [
      {
        name: 'shorterThan',
        value: 13,
      },
    ])

    if (validations.value) {
      this.setState({
        ruc: {
          value: ruc,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      ruc: {
        value: ruc,
        error: false,
        errorText: '',
      },
    })
  }

  handlePhone1Change = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const phone1 = value

    const validations = validateField(phone1, [
      {
        name: 'shorterThan',
        value: 40,
      },
    ])

    if (validations.value) {
      this.setState({
        phone1: {
          value: phone1,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      phone1: {
        value: phone1,
        error: false,
        errorText: '',
      },
    })
  }

  handlePhone2Change = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const phone2 = value

    const validations = validateField(phone2, [
      {
        name: 'shorterThan',
        value: 40,
      },
    ])

    if (validations.value) {
      this.setState({
        phone2: {
          value: phone2,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      phone2: {
        value: phone2,
        error: false,
        errorText: '',
      },
    })
  }

  handleWebsiteChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const website = value

    const validations = validateField(website, [
      {
        name: 'shorterThan',
        value: 300,
      },
    ])

    if (validations.value) {
      this.setState({
        website: {
          value: website,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      website: {
        value: website,
        error: false,
        errorText: '',
      },
    })
  }

  handleTypeChange = (
    type: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({
      type: {
        value: type,
        error: false,
        errorText: '',
      },
    })
  }

  handlePersonTypeChange = (
    personType: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({
      personType: {
        value: personType,
        error: false,
        errorText: '',
      },
    })
  }

  handleAddressChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const address = value

    const validations = validateField(address, [
      {
        name: 'shorterThan',
        value: 600,
      },
    ])

    if (validations.value) {
      this.setState({
        address: {
          value: address,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      address: {
        value: address,
        error: false,
        errorText: '',
      },
    })
  }

  handleCourierChange = (courier: CourierOption) => {
    let displayMap = false
    const gpsData = { coverage: [], latitude: 0, length: 0 }

    if (courier) {
      if (courier['name'] && courier['name'] == 'DriveIn') {
        displayMap = true
      } else {
        this.setState({
          gpsCoverage: JSON.parse(JSON.stringify(gpsData)),
          schemaCode: initialField,
        })
      }
    }

    this.setState({
      courier: {
        value: courier,
        error: false,
        errorText: '',
      },
      showMap: displayMap,
    })
  }

  handleSchemaCodeChange = (driverInSchema: DriveInSchemasOption) => {
    this.setState({
      schemaCode: {
        value: driverInSchema,
        error: false,
        errorText: '',
      },
    })
  }

  onContactAddClick = () => {
    const { contacts } = this.state

    contacts.push({
      name: initialField,
      email: initialField,
    })

    this.setState({ contacts })
  }

  onContactRemoveClick = (index: number) => {
    const { contacts } = this.state

    const result = [...contacts.slice(0, index), ...contacts.slice(index + 1)]

    if (result.length === 0) {
      return
    }

    this.setState({ contacts: result })
  }

  handleContactNameChange = (contactIndex: number, contactName: string) => {
    if (contactName.length > 40) {
      this.setState(prevState => {
        prevState.contacts[contactIndex].name = {
          value: contactName,
          error: true,
          errorText: 'La longitud máxima debe ser de 40 caracteres.',
        }
        return prevState
      })
      return
    }

    this.setState(prevState => {
      prevState.contacts[contactIndex].name = {
        value: contactName,
        error: false,
        errorText: '',
      }
      return prevState
    })
  }

  handleContactEmailChange = (contactIndex: number, contactEmail: string) => {
    if (contactEmail.length > 60) {
      this.setState(prevState => {
        prevState.contacts[contactIndex].email = {
          value: contactEmail,
          error: true,
          errorText: 'La longitud máxima debe ser de 60 caracteres.',
        }
        return prevState
      })
      return
    }

    this.setState(prevState => {
      prevState.contacts[contactIndex].email = {
        value: contactEmail,
        error: false,
        errorText: '',
      }
      return prevState
    })
  }
  handDeliverProductsChange = () => {
    const { deliverProducts } = this.state
    this.setState({ deliverProducts: !deliverProducts }, () => {
      if (this.state.deliverProducts) {
        this.setState({
          courier: {
            value: null,
            error: false,
            errorText: '',
          },
        })
      }
    })
  }

  handleStatusChange = (
    status: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({ status })
  }

  handTagProductsChange = () => {
    const { tagProducts } = this.state
    this.setState({ tagProducts: !tagProducts })
  }

  handleAssetsChange = (assets: Asset[]) => {
    const { oldAssets } = this.state
    const deleteAssets = oldAssets.filter(
      (oldAsset: Asset) =>
        assets.map((asset: Asset) => asset.id).indexOf(oldAsset.id) === -1
    )
    this.setState({ assets, assetsStatus: true, deleteAssets })
  }

  handleSlugChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const slug = value

    const validations = validateField(slug, [
      {
        name: 'shorterThan',
        value: 20,
      },
    ])

    if (validations.value) {
      this.setState({
        slug: {
          value: slug,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      slug: {
        value: slug,
        error: false,
        errorText: '',
      },
    })
  }

  addItemToMetaData = () => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux.push({
      key: { ...initialField },
      value: { ...initialField },
    })
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  removeMetada = (index: number) => {
    const metaDataArrayAux = this.state.metaDataArray
    const result = [
      ...metaDataArrayAux.slice(0, index),
      ...metaDataArrayAux.slice(index + 1),
    ]
    this.generateMetadataJson(result)
    this.setState({ metaDataArray: result })
  }

  handleKeyMetada = (index: number, key: string) => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux[index].key = {
      value: key,
      error: key.trim() == '' ? true : false,
      errorText: key.trim() == '' ? 'Campo Requerido' : '',
    }
    this.generateMetadataJson(metaDataArrayAux)
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  handleValueMetada = (index: number, value: string) => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux[index].value = {
      value: value,
      error: value.trim() == '' ? true : false,
      errorText: value.trim() == '' ? 'Campo Requerido' : '',
    }
    this.generateMetadataJson(metaDataArrayAux)
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  addItemToSchedule = () => {
    const scheduleArrayAux = this.state.scheduleArray
    scheduleArrayAux.push({
      id: 1,
      key: { ...initialField, value: 'mondayToSunday' },
      value: {
        ...initialField,
        value: { day: 'Lunes a Domingo', to: '', from: '', active: true },
      },
    })
    this.setState({ scheduleArray: scheduleArrayAux })
  }

  removeSchedule = (index: number) => {
    const scheduleArrayAux = this.state.scheduleArray
    const result = [
      ...scheduleArrayAux.slice(0, scheduleArrayAux.length),
      // ...scheduleArrayAux.slice(index + 1),
    ]
    this.generateScheduleJson(result)
    this.setState({
      scheduleArray: [],
      schedule: JSON.parse(JSON.stringify({})),
    })
  }

  handleKeySchedule = (index: number, key: string) => {
    const scheduleArrayAux = this.state.scheduleArray
    scheduleArrayAux[index].key = {
      value: key,
      error: key.trim() == '' ? true : false,
      errorText: key.trim() == '' ? 'Campo Requerido' : '',
    }
    this.generateScheduleJson(scheduleArrayAux)
    this.setState({ scheduleArray: scheduleArrayAux })
  }

  handleValueSchedule = (index: number, value: string) => {
    const scheduleArrayAux = this.state.scheduleArray
    scheduleArrayAux[index].value = {
      value: value,
      error: value.trim() == '' ? true : false,
      errorText: value.trim() == '' ? 'Campo Requerido' : '',
    }
    this.generateScheduleJson(scheduleArrayAux)
    this.setState({ scheduleArray: scheduleArrayAux })
  }

  handleRadioCheck = (value: boolean) => {
    let scheduleArrayAux = []

    if (value) {
      scheduleArrayAux.push({
        id: 1,
        key: { ...initialField, value: 'mondayToSunday' },
        value: {
          ...initialField,
          value: { day: 'Lunes a Domingo', to: '', from: '', active: true },
        },
      })
    } else {
      scheduleArrayAux.push(
        {
          id: 1,
          key: { ...initialField, value: 'monday' },
          value: {
            ...initialField,
            value: { day: 'Lunes', to: '', from: '', active: false },
          },
        },
        {
          id: 2,
          key: { ...initialField, value: 'tuesday' },
          value: {
            ...initialField,
            value: { day: 'Martes', to: '', from: '', active: false },
          },
        },
        {
          id: 3,
          key: { ...initialField, value: 'thursday' },
          value: {
            ...initialField,
            value: { day: 'Miercoels', to: '', from: '', active: false },
          },
        },
        {
          id: 4,
          key: { ...initialField, value: 'wednesday' },
          value: {
            ...initialField,
            value: { day: 'Jueves', to: '', from: '', active: false },
          },
        },
        {
          id: 5,
          key: { ...initialField, value: 'friday' },
          value: {
            ...initialField,
            value: { day: 'Viernes', to: '', from: '', active: false },
          },
        },
        {
          id: 6,
          key: { ...initialField, value: 'saturday' },
          value: {
            ...initialField,
            value: { day: 'Sabado', to: '', from: '', active: false },
          },
        },
        {
          id: 7,
          key: { ...initialField, value: 'sunday' },
          value: {
            ...initialField,
            value: { day: 'Domingo', to: '', from: '', active: false },
          },
        }
      )
    }

    this.generateScheduleJson(scheduleArrayAux)
    this.setState({ scheduleType: value, scheduleArray: scheduleArrayAux })
  }

  handleSelect = (scheduleData: any, index: any, type: string) => {
    let currentScheduleArray = this.state.scheduleArray

    if (type == 'to') {
      currentScheduleArray[index].value.value.to = scheduleData
    } else {
      currentScheduleArray[index].value.value.from = scheduleData
    }

    this.generateScheduleJson(currentScheduleArray)
  }

  handleSwitchChange = (index: number, value: boolean) => {
    let currentSwitchStatus = this.state.scheduleArray

    currentSwitchStatus[index].value.value.active = value

    this.generateScheduleJson(currentSwitchStatus)
    this.setState({ scheduleArray: currentSwitchStatus })
  }

  generateScheduleJson = (scheduleArray: any) => {
    let metadataJson = {}
    let scheduleJson = {}
    scheduleArray.forEach((item: any) => {
      scheduleJson[item.key.value] = item.value.value
    })
    metadataJson['horarios'] = scheduleJson

    this.setState({
      schedule: JSON.parse(JSON.stringify(metadataJson)),
    })
  }

  addScheduleToMetadata = () => {
    const currentSchedule = this.state.schedule
    this.setState({
      metaData: currentSchedule,
    })
  }

  generateMetadataJson = (metadataArray: Metadata[]) => {
    let metadataJson = {}
    metadataArray.forEach(item => {
      metadataJson[item.key.value] = item.value.value
    })
    this.setState({ metaData: JSON.parse(JSON.stringify(metadataJson)) })
  }

  validateMetadata = () => {
    this.setState({
      metaDataArray: this.state.metaDataArray.map(item => {
        if (item.key && item.key.value.trim() == '') {
          item.key.error = true
          item.key.errorText = 'Campo Requerido'
        }
        if (item.value && item.value.value.trim() == '') {
          item.value.error = true
          item.value.errorText = 'Campo Requerido'
        }
        return item
      }),
    })
  }

  scrollToError = () => {
    const inputs = getElements(inputIds)
    const { contacts, deliverProducts } = this.state
    let { error, errorsMap } = validateForm(inputs, inputIds)
    this.setState(prevState => {
      for (const key in errorsMap) {
        prevState[key] = errorsMap[key]
      }
      return prevState
    })

    let errorsMapSelects: Field[] = [
      {
        value: '',
        error: false,
        errorText: '',
      },
    ]

    if (deliverProducts) {
      selectsRequired.splice(selectsRequired.indexOf('courier'), 1)
    } else {
      selectsRequired.push('courier')
    }

    selectsRequired.forEach(id => {
      if (this.state[id] && !this.state[id].value) {
        error = false
        errorsMapSelects[id] = {
          value: '',
          error: true,
          errorText: 'Campo requerido',
        }
      }
    })

    errorsMapSelects['contacts'] = []
    contacts.forEach((contact, index) => {
      errorsMapSelects['contacts'][index] = contact
      if (!contact.name.value) {
        error = false
        errorsMapSelects['contacts'][index].name = {
          value: '',
          error: true,
          errorText: 'Campo requerido',
        }
      }
      if (!contact.email.value && contact.email.value.length === 0) {
        error = false
        errorsMapSelects['contacts'][index].email = {
          value: '',
          error: true,
          errorText: 'Campo requerido',
        }
      }
    })

    this.setState(prevState => {
      for (const key in errorsMapSelects) {
        prevState[key] = errorsMapSelects[key]
      }
      return prevState
    })

    const dynamicIds: string[] = []

    this.state.contacts.map((contact, index) => {
      dynamicIds.push('name' + index, 'email' + index)
    })
    const dynamicInputs = getElements(dynamicIds)

    const contactsLength = this.state.contacts.length

    this.state.contacts.map((key, index) => {
      const contactIndex = contactsLength - (index + 1)
      if (
        this.state.contacts[contactIndex] &&
        this.state.contacts[contactIndex].email.error
      ) {
        scrollTo(dynamicInputs['email' + contactIndex].element)
        error = false
      }
      if (
        this.state.contacts[contactIndex] &&
        this.state.contacts[contactIndex].name.error
      ) {
        scrollTo(dynamicInputs['name' + contactIndex].element)
        error = false
      }
    })

    inputIds.map(id => {
      if (this.state[id] && this.state[id].error) {
        scrollTo(inputs[id].element)
        error = false
      }
    })

    this.state.metaDataArray.map((item, index) => {
      if ((item.key && item.key.error) || (item.value && item.value.error)) {
        const element = document.getElementById('key-' + index)
        if (element) {
          scrollTo(element)
        }
        error = false
      }
    })

    return error
  }

  async mapElements() {
    const update = getElements(inputIds)
    this.setState({ inputs: update })
  }

  returnPath = (path: any) => {
    let gpsData = []
    let gpsValues = []

    gpsData = path.map((data: any) => {
      gpsValues = data.map((values: any) => {
        return { latitude: values.lat, length: values.lng }
      })
      return {
        coverage: gpsValues,
        latitude: data.latitude,
        length: data.lng,
      }
    })

    this.setState({ gpsCoverage: gpsData, gpsCoverageArray: gpsData })
  }

  componentDidMount() {
    this.mapElements()
  }

  render() {
    const {
      id,
      legalName,
      businessName,
      city,
      ruc,
      phone1,
      phone2,
      website,
      type,
      personType,
      address,
      deliverProducts,
      tagProducts,
      courier,
      contacts,
      assets,
      metaData,
      metaDataArray,
      slug,
      status,
      gpsCoverage,
      gpsCoverageArray,
      showMap,
      schemaCode,
      schedule,
      scheduleArray,
      scheduleType,
    } = this.state

    const { onUpdated } = this.props

    return (
      <Modal>
        {({ openModal }) => {
          return (
            <Mutation
              mutation={DELETE_FILE}
              onError={() => {
                openModal('ALERT', {
                  header: {
                    title: 'ALERTA',
                  },
                  description:
                    'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                  type: 'fail',
                })
              }}
            >
              {(deleteFile, { loading: loadingDeleteFile }) => (
                <ThemeContext.Consumer>
                  {notify => (
                    <Mutation
                      mutation={EDIT_SUPPLIER_MUTATION}
                      onCompleted={() => {
                        onUpdated()
                        notify &&
                          notify.onSetNotification &&
                          notify.onSetNotification({
                            type: 'ok',
                            message: 'Proveerdor guardado correctamente',
                          })
                      }}
                      onError={() => {
                        openModal('ALERT', {
                          header: {
                            title: 'ALERTA',
                          },
                          description:
                            'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                          type: 'fail',
                        })
                      }}
                    >
                      {(editSupplier, { loading, error }) => (
                        <React.Fragment>
                          <FormSection title="Información general">
                            <Paper>
                              <Grid.Row>
                                <Grid.Column md={4}>
                                  <Input
                                    id="legalName"
                                    value={legalName.value}
                                    onChange={this.handleLegalNameChange}
                                    type="text"
                                    error={legalName.error}
                                    label="Nombre legal"
                                  />
                                  {legalName.error ? (
                                    <Error>{legalName.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                                <Grid.Column md={4}>
                                  <Input
                                    id="businessName"
                                    value={businessName.value}
                                    onChange={this.handleBusinessNameChange}
                                    type="text"
                                    error={businessName.error}
                                    label="Nombre comercial"
                                    required
                                  />
                                  {businessName.error ? (
                                    <Error>{businessName.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                                <Grid.Column md={4}>
                                  <Input
                                    id="city"
                                    value={city.value}
                                    onChange={this.handleCityChange}
                                    type="text"
                                    error={city.error}
                                    label="Ciudad"
                                    required
                                  />
                                  {city.error ? (
                                    <Error>{city.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column md={4}>
                                  <Input
                                    id="ruc"
                                    value={ruc.value}
                                    onChange={this.handleRUCChange}
                                    type="text"
                                    error={ruc.error}
                                    label="RUC"
                                    required
                                  />
                                  {ruc.error ? (
                                    <Error>{ruc.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                                <Grid.Column md={4}>
                                  <Input
                                    id="phone1"
                                    value={phone1.value}
                                    onChange={this.handlePhone1Change}
                                    type="phone"
                                    error={phone1.error}
                                    label="Teléfono 1"
                                    required
                                  />
                                  {phone1.error ? (
                                    <Error>{phone1.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                                <Grid.Column md={4}>
                                  <Input
                                    value={phone2.value}
                                    onChange={this.handlePhone2Change}
                                    type="phone"
                                    error={false}
                                    label="Teléfono 2"
                                  />
                                  {phone2.error ? (
                                    <Error>{phone2.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column md={4}>
                                  <Input
                                    value={website.value}
                                    onChange={this.handleWebsiteChange}
                                    type="text"
                                    error={website.error}
                                    label="Website"
                                  />
                                  {website.error ? (
                                    <Error>{website.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                                <Grid.Column md={4}>
                                  <Select
                                    inputId="type"
                                    label="Tipo"
                                    error={type.error}
                                    placeholder="Seleccione un tipo"
                                    required
                                    options={[
                                      { label: 'Nacional', value: 'national' },
                                      {
                                        label: 'Internacional',
                                        value: 'international',
                                      },
                                    ]}
                                    value={type.value}
                                    onChange={this.handleTypeChange}
                                    backspaceRemovesValue={false}
                                  />
                                  {type.error ? (
                                    <Error>{type.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                                <Grid.Column md={4}>
                                  <Select
                                    inputId="personType"
                                    error={personType.error}
                                    label="Tipo de persona"
                                    placeholder="Seleccione un tipo"
                                    required
                                    options={[
                                      {
                                        label: 'Persona natural',
                                        value: 'natural',
                                      },
                                      {
                                        label: 'Persona jurídica',
                                        value: 'juridical',
                                      },
                                    ]}
                                    value={personType.value}
                                    onChange={this.handlePersonTypeChange}
                                    backspaceRemovesValue={false}
                                  />
                                  {personType.error ? (
                                    <Error>{personType.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column md={4}>
                                  <Input
                                    value={address.value}
                                    onChange={this.handleAddressChange}
                                    type="text"
                                    error={address.error}
                                    label="Dirección"
                                  />
                                  {address.error ? (
                                    <Error>{address.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                                <Grid.Column md={4}>
                                  <CourierSelector
                                    value={courier.value}
                                    isRequired={!deliverProducts}
                                    disabled={deliverProducts}
                                    error={courier.error}
                                    onChange={this.handleCourierChange}
                                  />
                                  {courier.error ? (
                                    <Error>{courier.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                                {showMap ? (
                                  <Grid.Column md={4}>
                                    <DriveInSchemaSelector
                                      value={schemaCode.value}
                                      isRequired={false}
                                      disabled={deliverProducts}
                                      error={schemaCode.error}
                                      onChange={this.handleSchemaCodeChange}
                                    />
                                    {courier.error ? (
                                      <Error>{schemaCode.errorText}</Error>
                                    ) : (
                                      <Spacer />
                                    )}
                                  </Grid.Column>
                                ) : (
                                  ''
                                )}
                                <Grid.Column md={4}>
                                  <Input
                                    value={slug.value}
                                    onChange={this.handleSlugChange}
                                    type="text"
                                    label="Slug"
                                    error={slug.error}
                                  />
                                  {slug.error ? (
                                    <Error>{slug.errorText}</Error>
                                  ) : (
                                    <Spacer />
                                  )}
                                </Grid.Column>
                                <Grid.Column md={4}>
                                  <Select
                                    id="status"
                                    label="Estado"
                                    placeholder="Seleccione un estado"
                                    required
                                    options={STATUS_OPTIONS_SUPPLIERS}
                                    value={status}
                                    onChange={this.handleStatusChange}
                                    backspaceRemovesValue={false}
                                  />
                                  <Spacer />
                                </Grid.Column>
                                <Grid.Column md={2}>
                                  <Spacer />
                                  <CheckBox
                                    id="deliverProducts"
                                    checked={deliverProducts}
                                    label="Entregar Productos"
                                    onChange={this.handDeliverProductsChange}
                                  />
                                </Grid.Column>
                                <Grid.Column md={2}>
                                  <Spacer />
                                  <CheckBox
                                    id="tagProducts"
                                    checked={tagProducts}
                                    label="Etiquetar Productos"
                                    onChange={this.handTagProductsChange}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Paper>
                          </FormSection>

                          {showMap ? (
                            <FormSection
                              title="Mapa"
                              subtitle="Seleccion el area de coertura"
                            >
                              <Paper>
                                <Grid.Row>
                                  <MapContainer>
                                    <Map
                                      returnPath={this.returnPath}
                                      gpsData={gpsCoverageArray}
                                      isEdit={true}
                                    />
                                  </MapContainer>
                                </Grid.Row>
                                {gpsCoverageArray.map(
                                  (value: any, index: any) => {
                                    return (
                                      <MapDetails>
                                        <TextArea
                                          key={index}
                                          id="mapPath"
                                          label="Area seleccionada"
                                          type="text"
                                          rows={4}
                                          value={value.coverage
                                            .map(
                                              (value: any) =>
                                                `Latitud: ${
                                                  value && value.latitude
                                                    ? value.latitude
                                                    : 0
                                                }        Longitud: ${
                                                  value && value.length
                                                    ? value.length
                                                    : 0
                                                }`
                                            )
                                            .join('\n')}
                                          multiple
                                          disabled
                                        />
                                      </MapDetails>
                                    )
                                  }
                                )}
                              </Paper>
                            </FormSection>
                          ) : (
                            ''
                          )}

                          <FormSection
                            title="Personas de contacto"
                            subtitle="Debe existir por lo menos una persona de contacto"
                            noBottomMargin
                          >
                            <Grid.Row>
                              {contacts.map((contact, index) => {
                                return (
                                  <Grid.Column md={4} key={index}>
                                    <Paper>
                                      <ContactGroup
                                        index={index}
                                        contactName={contact.name}
                                        onContactNameChange={
                                          this.handleContactNameChange
                                        }
                                        contactEmail={contact.email}
                                        onContactEmailChange={
                                          this.handleContactEmailChange
                                        }
                                        onCloseClick={this.onContactRemoveClick}
                                      />
                                    </Paper>
                                    <Spacer />
                                  </Grid.Column>
                                )
                              })}
                              <Grid.Column md={4}>
                                <Paper>
                                  <AddContactWrapper>
                                    <IconButton
                                      color="primary"
                                      icon={'plus'}
                                      onClick={this.onContactAddClick}
                                    />
                                    <AddContactText>
                                      Agregar un contacto
                                    </AddContactText>
                                  </AddContactWrapper>
                                </Paper>
                                <Spacer />
                              </Grid.Column>
                            </Grid.Row>
                          </FormSection>

                          <FormSection title={'Horarios'}>
                            <Grid.Row>
                              {scheduleArray.length == 0 ? (
                                <Grid.Column md={4}>
                                  <Paper>
                                    <AddFeatureWrapper>
                                      <IconButton
                                        color="primary"
                                        icon={'plus'}
                                        onClick={this.addItemToSchedule}
                                      />
                                      <AddFeatureText>
                                        Agregar horario
                                      </AddFeatureText>
                                    </AddFeatureWrapper>
                                  </Paper>
                                  <Spacer />
                                </Grid.Column>
                              ) : (
                                <Grid.Column md={12}>
                                  <Paper>
                                    <ScheduleGroup
                                      index={1}
                                      data={scheduleArray}
                                      scheduleType={scheduleType}
                                      isEdit={true}
                                      onCloseClick={this.removeSchedule}
                                      onChecked={this.handleRadioCheck}
                                      onSelectChange={this.handleSelect}
                                      onSwitchChange={this.handleSwitchChange}
                                    />
                                  </Paper>
                                  <Spacer />
                                </Grid.Column>
                              )}
                            </Grid.Row>
                          </FormSection>

                          <FormSection title={'Agregar Meta Data'}>
                            <Grid.Row>
                              {metaDataArray.map((data, index) => {
                                return (
                                  <Grid.Column md={4} key={index}>
                                    <Paper>
                                      <MetadataGroup
                                        index={index}
                                        metadata={data}
                                        onKeyChange={this.handleKeyMetada}
                                        onValueChange={this.handleValueMetada}
                                        onCloseClick={this.removeMetada}
                                      />
                                    </Paper>
                                    <Spacer />
                                  </Grid.Column>
                                )
                              })}
                              <Grid.Column md={4}>
                                <Paper>
                                  <AddFeatureWrapper>
                                    <IconButton
                                      color="primary"
                                      icon={'plus'}
                                      onClick={this.addItemToMetaData}
                                    />
                                    <AddFeatureText>
                                      Agregar nuevo item
                                    </AddFeatureText>
                                  </AddFeatureWrapper>
                                </Paper>
                                <Spacer />
                              </Grid.Column>
                            </Grid.Row>
                          </FormSection>

                          <FormSection title={'Carga de archivos'}>
                            <Grid.Row>
                              <Grid.Column>
                                <Paper>
                                  <AssetSelector
                                    dropZoneSupport
                                    colorButton="secondary"
                                    textButton="Seleccionar archivos"
                                    accept="application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,image/gif,image/jpeg,image/png"
                                    assets={assets}
                                    onChange={this.handleAssetsChange}
                                  />
                                </Paper>
                              </Grid.Column>
                            </Grid.Row>
                          </FormSection>
                          <AlignRight>
                            <Mutation
                              mutation={UPLOAD_SUPPLIER_FILE}
                              onError={() => {
                                openModal('ALERT', {
                                  header: {
                                    title: 'ALERTA',
                                  },
                                  description:
                                    'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                  type: 'fail',
                                })
                              }}
                            >
                              {(
                                uploadSupplierFile,
                                { loading: loadinUploadfile }
                              ) => (
                                <SaveButton
                                  color="primary"
                                  disabled={
                                    loadinUploadfile ||
                                    loadingDeleteFile ||
                                    loading
                                  }
                                  onClick={async () => {
                                    this.validateMetadata()
                                    let nextError = this.scrollToError()
                                    if (nextError) {
                                      const uploadedAssets: Asset[] = []
                                      let prevAssets: Asset[] = []

                                      if (this.state.assetsStatus) {
                                        try {
                                          for (const deleteAsset of this.state
                                            .deleteAssets) {
                                            const fileID = deleteAsset.url.substring(
                                              deleteAsset.url.lastIndexOf('/') +
                                                1
                                            )
                                            if (deleteAsset.type !== 'video') {
                                              await deleteFile({
                                                variables: {
                                                  id: fileID,
                                                },
                                              })
                                            }
                                          }
                                        } catch (error) {
                                          // TODO: Delete all uploaded files since one of them failed
                                          // tslint:disable-next-line:no-console
                                          console.log(
                                            'error deleting file',
                                            error
                                          )
                                          return
                                        }

                                        try {
                                          let index = 0

                                          for (const asset of assets) {
                                            index++
                                            if (asset.file != null) {
                                              const response = await uploadSupplierFile(
                                                {
                                                  variables: {
                                                    file: asset.file,
                                                  },
                                                }
                                              )

                                              if (response) {
                                                const {
                                                  url,
                                                } = response.data.uploadSupplierFile

                                                uploadedAssets.push({
                                                  id: '',
                                                  type: 'doc',
                                                  url,
                                                  order: index,
                                                })
                                              }
                                            } else {
                                              uploadedAssets.push({
                                                id: asset.id,
                                                type: asset.type,
                                                url: asset.url,
                                                order: index,
                                              })
                                            }
                                          }

                                          // deleteFile
                                          prevAssets = [...uploadedAssets].map(
                                            (asset: Asset) => ({
                                              url: asset.url,
                                              type: asset.type,
                                              order: asset.order,
                                            })
                                          )
                                        } catch (error) {
                                          // TODO: Delete all uploaded files since one of them failed
                                          // tslint:disable-next-line:no-console
                                          console.log(
                                            'error uploading file',
                                            error
                                          )
                                          return
                                        }
                                      } else {
                                        prevAssets = assets
                                      }

                                      let newMetaData = metaData

                                      if (scheduleArray.length > 0) {
                                        let currentMetaData = metaData
                                        currentMetaData.horarios =
                                          schedule['horarios']
                                        newMetaData = JSON.parse(
                                          JSON.stringify(currentMetaData)
                                        )
                                      }

                                      editSupplier({
                                        variables: {
                                          input: {
                                            id,
                                            legalName: legalName.value,
                                            businessName: businessName.value,
                                            city: city.value,
                                            ruc: ruc.value,
                                            website: website.value,
                                            phone1: phone1.value,
                                            phone2: phone2.value,
                                            address: address.value,
                                            type: type && type.value['value'],
                                            personType:
                                              personType &&
                                              personType.value['value'],
                                            deliverProducts,
                                            tagProducts,
                                            contacts: contacts.map(contact => ({
                                              name: contact.name.value,
                                              email: contact.email.value,
                                            })),
                                            assets: prevAssets,
                                            courierId:
                                              courier.value &&
                                              courier.value['id']
                                                ? courier.value['id']
                                                : null,
                                            metaData: newMetaData,
                                            gpsCoverage: JSON.parse(
                                              JSON.stringify(gpsCoverage)
                                            ),
                                            schemaCode:
                                              schemaCode &&
                                              schemaCode.value &&
                                              schemaCode.value.code
                                                ? schemaCode.value.code
                                                : '',
                                            slug: slug.value,
                                            status: status && status['value'],
                                          },
                                        },
                                      })
                                    }
                                  }}
                                >
                                  {loading
                                    ? 'Editando proveedor...'
                                    : 'Editar Proveedor'}
                                </SaveButton>
                              )}
                            </Mutation>
                          </AlignRight>
                        </React.Fragment>
                      )}
                    </Mutation>
                  )}
                </ThemeContext.Consumer>
              )}
            </Mutation>
          )
        }}
      </Modal>
    )
  }
}

export default EditSupplierForm
