import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import * as Grid from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import {
  Input,
  Error,
  CheckBox,
  TextArea,
} from '../../../../components/FormElements'
import { Spacer, ActionWrapper } from '../../../../components/Globals'
import Modal from '../../../../components/Modals/ModalRoot'
import CategorySelector, {
  CategoryOption,
} from '../../../../components/Selectors/CategorySelector'
import { CancelButton } from '../../../../components/Buttons'

import { SaveButton } from '../style'

import ThemeContext from '../../../../context/ThemeContext'

const CREATE_CATEGORY_MUTATION = gql`
  mutation createCategoryMutation($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
    }
  }
`

interface State {
  name: string
  nameError: boolean
  description: string
  descriptionError: boolean
  category: CategoryOption | null
  hasParent: boolean
  categoryError: boolean
  status: string
  statusError: boolean
  createError: boolean
}

interface Props {
  onCreated: () => void
}

class CreateCategoryForm extends React.Component<Props, State> {
  state: State = {
    name: '',
    nameError: false,
    description: '',
    descriptionError: false,
    category: null,
    hasParent: false,
    categoryError: false,
    status: 'active',
    statusError: false,
    createError: false,
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    if (name.length >= 40) {
      this.setState({
        name,
        nameError: true,
      })

      return
    }

    this.setState({
      name,
      nameError: false,
    })
  }

  handleDescriptionChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = value

    if (description.length >= 400) {
      this.setState({
        description,
        descriptionError: true,
      })

      return
    }

    this.setState({
      description,
      descriptionError: false,
    })
  }

  handleCategoryChange = (category: CategoryOption) => {
    this.setState({ category, categoryError: false })
  }

  handleHasParentChange = () => {
    const { hasParent } = this.state
    this.setState({ hasParent: !hasParent })
  }

  render() {
    const {
      name,
      nameError,
      description,
      descriptionError,
      category,
      status,
      categoryError,
      hasParent,
    } = this.state
    return (
      <Modal>
        {({ openModal }) => {
          return (
            <React.Fragment>
              <Paper>
                <Grid.Row>
                  <Grid.Column md={4}>
                    <Input
                      id="name"
                      value={name}
                      type="text"
                      label="Nombre de la categoría"
                      onChange={this.handleNameChange}
                      required
                      error={nameError}
                    />
                    {nameError ? (
                      <Error>
                        La longitud máxima debe ser de 40 caracteres.
                      </Error>
                    ) : (
                      <Spacer />
                    )}
                    <CheckBox
                      id="hasParent"
                      checked={hasParent}
                      label="¿Tiene padre?"
                      onChange={this.handleHasParentChange}
                    />
                  </Grid.Column>
                  {hasParent && (
                    <Grid.Column md={4}>
                      <CategorySelector
                        value={category}
                        onChange={this.handleCategoryChange}
                        disabled={!hasParent}
                        isMulti={false}
                        isRequired={true}
                        error={categoryError}
                      />
                      {categoryError ? (
                        <Error>Seleccione una categoría</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                  )}
                  <Grid.Column md={4}>
                    <TextArea
                      id="description"
                      value={description}
                      type="text"
                      rows={7}
                      label="Descripción larga"
                      onChange={this.handleDescriptionChange}
                      error={descriptionError}
                    />
                    {descriptionError ? (
                      <Error>
                        La longitud máxima debe ser de 400 caracteres.
                      </Error>
                    ) : (
                      <Spacer />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Paper>
              <ThemeContext.Consumer>
                {notify => (
                  <Mutation
                    mutation={CREATE_CATEGORY_MUTATION}
                    onCompleted={() => {
                      this.props.onCreated()
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message: 'Categoría creada correctamente',
                        })
                    }}
                    onError={() => {
                      openModal('ALERT', {
                        header: {
                          title: 'ALERTA',
                        },
                        description:
                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                        type: 'fail',
                      })
                    }}
                  >
                    {(createCategory, { loading, error }) => {
                      return (
                        <ActionWrapper>
                          <CancelButton color="secondary" to="categories">
                            Cancelar
                          </CancelButton>
                          <SaveButton
                            color="primary"
                            disabled={loading}
                            onClick={() => {
                              let error = false
                              if (!name || nameError) {
                                error = true
                                this.setState({
                                  nameError: true,
                                })
                              }
                              if ((!category && hasParent) || categoryError) {
                                error = true
                                this.setState({
                                  categoryError: true,
                                })
                              }

                              if (error || descriptionError) {
                                return
                              }
                              createCategory({
                                variables: {
                                  input: {
                                    name,
                                    description,
                                    parentId: category && category['id'],
                                    status,
                                  },
                                },
                              })
                            }}
                          >
                            {loading
                              ? 'Creando categoría...'
                              : 'Crear Categoría'}
                          </SaveButton>
                        </ActionWrapper>
                      )
                    }}
                  </Mutation>
                )}
              </ThemeContext.Consumer>
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
}

export default CreateCategoryForm
