import React from 'react'
import gql from 'graphql-tag'

import PageWrapper from '../../components/PageWrapper'
import Paper from '../../components/Paper'
import * as Grid from '../../components/Grid'

import { Mutation } from 'react-apollo'
import { Button } from '../../components/Buttons'

import { Instruction, StyledExportButtonContainer } from './style'

import ThemeContext from '../../context/ThemeContext'

import { DatePicker, Input, Error } from '../../components/FormElements'
import { Spacer } from '../../components/Globals'

import { Field, initialField } from '../../helpers/validations'

interface State {
  to: Date
  from: Date
  email: Field
}

const EXPORT_ORDERS_MUTATION = gql`
  mutation exportOrders($input: ExportOrdersInput!) {
    exportOrders(input: $input)
  }
`
type ExportOrdersInput = {
  to: string
  from: string
  email: string
  status: string
}

class ExportOrders extends React.Component<{}, State> {
  state: State = {
    to: new Date(),
    from: new Date(),
    email: initialField,
  }

  checkTime = (i: Number) => {
    return i < 10 ? '0' + i : i
  }

  formatDate = (date: Date) => {
    var dd = String(date.getDate()).padStart(2, '0')
    var mm = String(date.getMonth() + 1).padStart(2, '0')
    var yyyy = date.getFullYear()

    var hh = this.checkTime(date.getHours())
    var min = this.checkTime(date.getMinutes())
    var ss = this.checkTime(date.getSeconds())

    var formDate = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + ss
    return formDate
  }

  handleDateFromChange = (from: Date) => {
    this.setState({
      from,
    })
  }

  handleDateToChange = (to: Date) => {
    this.setState({
      to,
    })
  }

  handleEmailToChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const email = value
    let errorEmail = false
    let errorEmailText = ''

    if (!this.validateEmail(email)) {
      errorEmail = true
      errorEmailText = 'Email no válido'
    }

    this.setState({
      email: {
        value: email,
        error: errorEmail,
        errorText: errorEmailText,
      },
    })
  }

  validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  render() {
    const { to, from, email } = this.state
    return (
      <ThemeContext.Consumer>
        {notify => (
          <PageWrapper title="Reporte de ventas sin logística">
            <Paper>
              <Grid.Row>
                <Grid.Column md={12}>
                  <Instruction>
                    Seleccione el rango de fecha del cual desea generar el
                    reporte y el correo al que desea llegue el informe.
                  </Instruction>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column md={4}>
                  <DatePicker
                    label="Desde"
                    date={from}
                    onChange={this.handleDateFromChange}
                    dateFormat="yyyy-MM-dd h:mm:ss"
                    showTimeSelect
                  />
                </Grid.Column>
                <Grid.Column md={4}>
                  <DatePicker
                    label="Hasta"
                    date={to}
                    onChange={this.handleDateToChange}
                    dateFormat="yyyy-MM-dd h:mm:ss"
                    showTimeSelect
                  />
                </Grid.Column>
                <Grid.Column md={3}>
                  <React.Fragment>
                    <Input
                      id="email"
                      value={email.value}
                      onChange={this.handleEmailToChange}
                      type="text"
                      label="Email"
                    />
                    {email.error ? (
                      <Error>{email.errorText}</Error>
                    ) : (
                      <Spacer />
                    )}
                  </React.Fragment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column md={4}>
                  <StyledExportButtonContainer md={4}>
                    <Mutation
                      mutation={EXPORT_ORDERS_MUTATION}
                      onCompleted={() => {
                        notify &&
                          notify.onSetNotification &&
                          notify.onSetNotification({
                            type: 'ok',
                            message:
                              'Se ha enviado un correo con las órdenes exportadas',
                          })
                      }}
                      onError={() => {
                        notify &&
                          notify.onSetNotification &&
                          notify.onSetNotification({
                            type: 'fail',
                            message:
                              'Se ha produjo en error inténtelo más tarde',
                          })
                      }}
                    >
                      {(exportProducts, { loading, error }) => {
                        return (
                          <Button
                            color="secondary"
                            onClick={() => {
                              const exportOrders: ExportOrdersInput = {
                                to: this.formatDate(to),
                                from: this.formatDate(from),
                                email: email.value,
                                status: 'approved',
                              }

                              exportProducts({
                                variables: {
                                  input: exportOrders,
                                },
                              })
                            }}
                            disabled={email.error || email.value === ''}
                          >
                            Exportar órdenes
                          </Button>
                        )
                      }}
                    </Mutation>
                  </StyledExportButtonContainer>
                </Grid.Column>
              </Grid.Row>
            </Paper>
          </PageWrapper>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default ExportOrders
