import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
//import { format } from 'date-fns'
import {
  TableContainer,
  Container,
  ColumnDescription,
  ColumnTitle,
  Title,
} from '../style'
import { Spacer } from '../../../../components/Globals'

const GET_COUPON_BY_ID = gql`
  query getCouponById($code: String!) {
    coupon(code: $code) {
      id
      code
      identification
      redeem
      value
      expires
      expiresFrom
      expiresTo
      createdAt
      updatedAt
    }
  }
`

interface Data {
  coupon: {
    code: string
    expires: string
    createdAt: string
    updatedAt: string
  }
}

interface RouterParams
  extends RouteComponentProps<{
      code?: string
    }> {}

function CouponDetailsTable({
  match: {
    params: { code },
  },
}: RouterParams) {
  return (
    <Query
      variables={{
        code: code,
      }}
      query={GET_COUPON_BY_ID}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult<Data, any>) => {
        if (loading) {
          return <p>Loading...</p>
        }
        if (error) {
          return <p>Error</p>
        }
        if (!data) {
          return <p>No Data</p>
        }
        if (!data.coupon) {
          return <p>No Items</p>
        }

        const { code, createdAt, updatedAt } = data.coupon
        var d = new Date(createdAt)
        const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
        const mm = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
        const yyyy = d.getFullYear()
        const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
        const min = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
        const sec = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
        const date =
          yyyy + '-' + mm + '-' + dd + ' ' + hour + ':' + min + ':' + sec

        var dUpt = new Date(updatedAt)
        const ddUpt = (dUpt.getDate() < 10 ? '0' : '') + dUpt.getDate()
        const mmUpt =
          (dUpt.getMonth() + 1 < 10 ? '0' : '') + (dUpt.getMonth() + 1) // January is 0!
        const yyyyUpt = dUpt.getFullYear()
        const hourUpt = (dUpt.getHours() < 10 ? '0' : '') + dUpt.getHours()
        const minUpt = (dUpt.getMinutes() < 10 ? '0' : '') + dUpt.getMinutes()
        const secUpt = (dUpt.getSeconds() < 10 ? '0' : '') + dUpt.getSeconds()
        const dateUpt =
          yyyyUpt +
          '-' +
          mmUpt +
          '-' +
          ddUpt +
          ' ' +
          hourUpt +
          ':' +
          minUpt +
          ':' +
          secUpt
        return (
          <React.Fragment>
            <Title>Cupon: {code}</Title>
            <Spacer />
            Detalles de creación en el sistema
            <Spacer />
            <TableContainer>
              <Container>
                <ColumnTitle>Fecha ingreso</ColumnTitle>
                <ColumnDescription>{date}</ColumnDescription>
                <ColumnTitle>Fecha de actualización</ColumnTitle>
                <ColumnDescription>{dateUpt}</ColumnDescription>
              </Container>
            </TableContainer>
          </React.Fragment>
        )
      }}
    </Query>
  )
}

export default withRouter(CouponDetailsTable)
