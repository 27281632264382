import React from 'react'
import { Route, Switch } from 'react-router'
import { BrowserRouter as Router, Redirect } from 'react-router-dom'

import signedOutFallback from '../helpers/signed-out-fallback'

import BrandForm from '../views/Brand/Form'
import BrandList from '../views/Brand/List'
import CategoryForm from '../views/Category/Form'
import CategoryList from '../views/Category/List'
import ProductForm from '../views/Product/Form'
import ProductList from '../views/Product/List'
import VariationForm from '../views/Variation/Form'
import VariationList from '../views/Variation/List'
import SupplierList from '../views/Supplier/List'
import SupplierForm from '../views/Supplier/Form'
import ProcessOrderList from '../views/ProcessOrder/List'
import PurchaseList from '../views/Purchase/List'
import {
  FulfillmentOrderList,
  FulfillmentOrderItemsList,
} from '../views/FulfillmentOrder/List'
import {
  PurchaseOrderList,
  PurchaseOrderItemsList,
} from '../views/PurchaseOrder/List'
import { OrderList, OrderTransactionList } from '../views/Order/List'
import { CouponList, CouponTransactionList } from '../views/Coupons/List'
import LoginForm from '../views/Login/Form'
import Home from '../views/Home'
import ProductBulkImport from '../views/ProductBulkImport'
import ProductUpdateBulkImport from '../views/ProductUpdateBulkImport'
import { CommerceList } from '../views/Commerce/List'
import { BalanceTransactionsReportList } from '../views/Reports/List'
import CommerceForm from '../views/Commerce/Form'
import { PqrsList } from '../views/Pqrs/List'
import SalesBulkExport from '../views/SalesBulkExport'
import OrdersBulkExport from '../views/OrdersBulkExport'
import ApprovalsList from '../views/Approvals/List'
import PromosList from '../views/Promo/List'
import PromoTypeForm from '../views/PromoType/Form'
import PromoTypeList from '../views/PromoType/List'

import ThemeContext from '../context/ThemeContext'

const LoginFallBack = signedOutFallback(() => <Redirect to="/" />, LoginForm)

const LogoutFallBack = () => {
  localStorage.clear()
  sessionStorage.clear()
  return <Redirect to="/login" />
}

const SupplierListFallBack = signedOutFallback(SupplierList, () => (
  <Redirect to="/login?r=suppliers" />
))
const SupplierFormFallBack = signedOutFallback(
  SupplierForm,
  ({
    match: {
      params: { id, action },
    },
  }: any) => {
    if (id) {
      return <Redirect to={`/login?r=/suppliers/${id}/${action}`} />
    }
    return <Redirect to={`/login?r=/suppliers/${action}`} />
  }
)
const BrandListFallBack = signedOutFallback(BrandList, () => (
  <Redirect to="/login?r=brands" />
))
const BrandFormFallBack = signedOutFallback(
  BrandForm,
  ({
    match: {
      params: { id, action },
    },
  }: any) => {
    if (id) {
      return <Redirect to={`/login?r=/brands/${id}/${action}`} />
    }
    return <Redirect to={`/login?r=/brands/${action}`} />
  }
)
const CategoryListFallBack = signedOutFallback(CategoryList, () => (
  <Redirect to="/login?r=categories" />
))
const CategoryFormFallBack = signedOutFallback(
  CategoryForm,
  ({
    match: {
      params: { id, action },
    },
  }: any) => {
    if (id) {
      return <Redirect to={`/login?r=/categories/${id}/${action}`} />
    }
    return <Redirect to={`/login?r=/categories/${action}`} />
  }
)
const ProductListFallBack = signedOutFallback(ProductList, () => (
  <Redirect to="/login?r=products" />
))
const ProductFormFallBack = signedOutFallback(
  ProductForm,
  ({
    match: {
      params: { id, action },
    },
  }: any) => {
    if (id) {
      return <Redirect to={`/login?r=/products/${id}/${action}`} />
    }
    return <Redirect to={`/login?r=/products/${action}`} />
  }
)
const VariationListFallBack = signedOutFallback(VariationList, () => (
  <Redirect to="/login?r=variations" />
))
const VariationFormFallBack = signedOutFallback(
  VariationForm,
  ({
    match: {
      params: { id, action },
    },
  }: any) => {
    if (id) {
      return <Redirect to={`/login?r=/variations/${id}/${action}`} />
    }
    return <Redirect to={`/login?r=/variations/${action}`} />
  }
)
const PurchaseListFallBack = signedOutFallback(PurchaseList, () => (
  <Redirect to="/login?r=pending-orders" />
))

const OrderListFallBack = signedOutFallback(OrderList, () => (
  <Redirect to="/login?r=orders" />
))

const CouponListFallBack = signedOutFallback(CouponList, () => (
  <Redirect to="/login?r=coupons" />
))

const OrderTransactionListFallBack = signedOutFallback(
  OrderTransactionList,
  ({
    match: {
      params: { id },
    },
  }: any) => {
    return <Redirect to={`/login?r=/orders/${id}`} />
  }
)

const CouponTransactionFallBack = signedOutFallback(
  CouponTransactionList,
  ({
    match: {
      params: { code },
    },
  }: any) => {
    return <Redirect to={`/login?r=/coupons/${code}`} />
  }
)

const ProcessOrderListFallBack = signedOutFallback(ProcessOrderList, () => (
  <Redirect to="/login?r=process-orders" />
))

const HomeFallBack = signedOutFallback(Home, () => <Redirect to="/login" />)

const PurchaseOrderListFallBack = signedOutFallback(PurchaseOrderList, () => (
  <Redirect to="/login?r=fulfillment" />
))

const FulfillmentListFallBack = signedOutFallback(FulfillmentOrderList, () => (
  <Redirect to="/login?r=fulfillment" />
))

const FulfillmentItemsListFallBack = signedOutFallback(
  FulfillmentOrderItemsList,
  ({
    match: {
      params: { id },
    },
  }: any) => {
    if (id) {
      return <Redirect to={`/login?r=fulfillment/${id}/purchases`} />
    }
    return <Redirect to="/login?r=fulfillment" />
  }
)

const ProductBulkImportFallBack = signedOutFallback(ProductBulkImport, () => (
  <Redirect to="/login?r=products-bulk-import" />
))

const ProductUpdateBulkImportFallBack = signedOutFallback(
  ProductUpdateBulkImport,
  () => <Redirect to="/login?r=products-update-bulk-import" />
)

const SalesBulkImportFallBack = signedOutFallback(SalesBulkExport, () => (
  <Redirect to="/login?r=sales-report" />
))

const OrdersBulkImportFallBack = signedOutFallback(OrdersBulkExport, () => (
  <Redirect to="/login?r=orders-report" />
))

const CommerceListFallBack = signedOutFallback(CommerceList, () => (
  <Redirect to="/login?r=commerces" />
))

const PurchaseOrderItemsListFallBack = signedOutFallback(
  PurchaseOrderItemsList,
  ({
    match: {
      params: { id },
    },
  }: any) => {
    return <Redirect to={`/login?r=/purchase-orders/${id}/purchases`} />
  }
)

const CommerceFormFallBack = signedOutFallback(
  CommerceForm,
  ({
    match: {
      params: { id, action },
    },
  }: any) => {
    if (id) {
      return <Redirect to={`/login?r=/commerces/${id}/${action}`} />
    }
    return <Redirect to={`/login?r=/commerces/${action}`} />
  }
)

const CommerceBalanceTransactionsFallBack = signedOutFallback(
  BalanceTransactionsReportList,
  ({
    match: {
      params: { id, action },
    },
  }: any) => {
    if (id) {
      return <Redirect to={`/login?r=/commerces/${id}/${action}`} />
    }
    return <Redirect to={`/login?r=/commerces/${action}`} />
  }
)

const PqrsListFallBack = signedOutFallback(PqrsList, () => (
  <Redirect to="/login?r=pqrs" />
))

const ApprovalsListFallBack = signedOutFallback(ApprovalsList, () => (
  <Redirect to="/login?r=approve" />
))

const PromosListFallBack = signedOutFallback(PromosList, () => (
  <Redirect to="/login?r=promo" />
))

const PromoTypeListFallBack = signedOutFallback(PromoTypeList, () => (
  <Redirect to="/login?r=promo-types" />
))

const PromoTypeFormFallBack = signedOutFallback(
  PromoTypeForm,
  ({
    match: {
      params: { id, action },
    },
  }: any) => {
    if (id) {
      return <Redirect to={`/login?r=/promo-types/${id}/${action}`} />
    }
    return <Redirect to={`/login?r=/promo-types/${action}`} />
  }
)

function Routes() {
  return (
    <ThemeContext.Consumer>
      {context => {
        return context.login.user == 'pqrs-callcenter' ? (
          <Router>
            <Switch>
              <Route path="/" exact={true} component={PqrsListFallBack} />
              <Route path="/login" exact={true} component={LoginFallBack} />
              <Route path="/logout" exact={true} component={LogoutFallBack} />
              <Route path="/pqrs" exact={true} component={PqrsListFallBack} />
              <Route
                path="/orders"
                exact={true}
                component={OrderListFallBack}
              />
              <Route
                path="/orders/:id"
                exact={true}
                component={OrderTransactionListFallBack}
              />
              <Route
                path="/pending-orders"
                exact={true}
                component={PurchaseListFallBack}
              />
              <Route
                path="/coupons"
                exact={true}
                component={CouponListFallBack}
              />
              <Route
                path="/coupons/:code"
                exact={true}
                component={CouponTransactionFallBack}
              />

              <Route
                path="/purchase-orders"
                exact={true}
                component={PurchaseOrderListFallBack}
              />
              <Route
                path="/purchase-orders/:id/purchases"
                exact={true}
                component={PurchaseOrderItemsListFallBack}
              />
              <Route
                path="/process-orders"
                exact={true}
                component={ProcessOrderListFallBack}
              />
              <Route
                path="/sales-report"
                exact={true}
                component={SalesBulkImportFallBack}
              />
              <Route
                path="/orders-report"
                exact={true}
                component={OrdersBulkImportFallBack}
              />
              <Route path="*" exact={true} component={PqrsListFallBack} />
            </Switch>
          </Router>
        ) : context.login.user == 'superadmin-all-system' ? (
          <Router>
            <Switch>
              <Route path="/" exact={true} component={HomeFallBack} />
              <Route path="/partners" exact={true} component={() => null} />
              <Route
                path="/partners/create"
                exact={true}
                component={() => null}
              />
              <Route
                path="/partners/:id/edit"
                exact={true}
                component={() => null}
              />
              <Route path="/partners/:id" component={() => null} />

              <Route path="/miles" exact={true} component={() => null} />
              <Route path="/miles/create" exact={true} component={() => null} />
              <Route
                path="/miles/:id/edit"
                exact={true}
                component={() => null}
              />
              <Route path="/miles/:id" exact={true} component={() => null} />

              <Route
                path="/suppliers"
                exact={true}
                component={SupplierListFallBack}
              />
              <Route
                path="/suppliers/:action"
                exact={true}
                component={SupplierFormFallBack}
              />
              <Route
                path="/suppliers/:id/:action"
                exact={true}
                component={SupplierFormFallBack}
              />

              <Route
                path="/brands"
                exact={true}
                component={BrandListFallBack}
              />
              <Route
                path="/brands/:action"
                exact={true}
                component={BrandFormFallBack}
              />
              <Route
                path="/brands/:id/:action"
                exact={true}
                component={BrandFormFallBack}
              />

              <Route
                path="/categories"
                exact={true}
                component={CategoryListFallBack}
              />
              <Route
                path="/categories/:action"
                exact={true}
                component={CategoryFormFallBack}
              />
              <Route
                path="/categories/:id/:action"
                exact={true}
                component={CategoryFormFallBack}
              />

              <Route
                path="/products"
                exact={true}
                component={ProductListFallBack}
              />
              <Route
                path="/products/:action"
                exact={true}
                component={ProductFormFallBack}
              />
              <Route
                path="/products/:id/:action"
                exact={true}
                component={ProductFormFallBack}
              />

              <Route
                path="/variations"
                exact={true}
                component={VariationListFallBack}
              />
              <Route
                path="/variations/:action"
                exact={true}
                component={VariationFormFallBack}
              />
              <Route
                path="/variations/:id/:action"
                exact={true}
                component={VariationFormFallBack}
              />

              <Route path="/pqrs" exact={true} component={PqrsListFallBack} />
              <Route path="/pqrs/create" exact={true} component={() => null} />
              <Route
                path="/pqrs/:id/edit"
                exact={true}
                component={() => null}
              />
              <Route path="/pqrs/:id" exact={true} component={() => null} />

              <Route
                path="/orders"
                exact={true}
                component={OrderListFallBack}
              />
              <Route
                path="/coupons"
                exact={true}
                component={CouponListFallBack}
              />

              <Route
                path="/coupons/:code"
                exact={true}
                component={CouponTransactionFallBack}
              />

              <Route
                path="/orders/:id"
                exact={true}
                component={OrderTransactionListFallBack}
              />

              <Route
                path="/pending-orders"
                exact={true}
                component={PurchaseListFallBack}
              />

              <Route
                path="/purchase-orders"
                exact={true}
                component={PurchaseOrderListFallBack}
              />

              <Route
                path="/fulfillment"
                exact={true}
                component={FulfillmentListFallBack}
              />

              <Route
                path="/fulfillment/:id/purchases"
                exact={true}
                component={FulfillmentItemsListFallBack}
              />

              <Route
                path="/purchase-orders/:id/purchases"
                exact={true}
                component={PurchaseOrderItemsListFallBack}
              />

              <Route
                path="/process-orders"
                exact={true}
                component={ProcessOrderListFallBack}
              />

              <Route path="/login" exact={true} component={LoginFallBack} />
              <Route path="/logout" exact={true} component={LogoutFallBack} />

              <Route
                path="/products-bulk-import"
                exact={true}
                component={ProductBulkImportFallBack}
              />

              <Route
                path="/products-update-bulk-import"
                exact={true}
                component={ProductUpdateBulkImportFallBack}
              />

              <Route
                path="/sales-report"
                exact={true}
                component={SalesBulkImportFallBack}
              />

              <Route
                path="/orders-report"
                exact={true}
                component={OrdersBulkImportFallBack}
              />

              <Route
                path="/commerces"
                exact={true}
                component={CommerceListFallBack}
              />

              <Route
                path="/commerces/:action"
                exact={true}
                component={CommerceFormFallBack}
              />

              <Route
                path="/commerces/:id/:action"
                exact={true}
                component={CommerceFormFallBack}
              />

              <Route
                path="/reports/balance/:id"
                exact={true}
                component={CommerceBalanceTransactionsFallBack}
              />

              <Route
                path="/approve"
                exact={true}
                component={ApprovalsListFallBack}
              />

              <Route
                path="/promo"
                exact={true}
                component={PromosListFallBack}
              />

              <Route
                path="/promo-types"
                exact={true}
                component={PromoTypeListFallBack}
              />
              <Route
                path="/promo-types/:action"
                exact={true}
                component={PromoTypeFormFallBack}
              />
              <Route
                path="/promo-types/:id/:action"
                exact={true}
                component={PromoTypeFormFallBack}
              />

              <Route path="*" exact={true} component={HomeFallBack} />
            </Switch>
          </Router>
        ) : (
          <Router>
            <Switch>
              <Route path="/" exact={true} component={HomeFallBack} />
              <Route path="/login" exact={true} component={LoginFallBack} />
              <Route path="/logout" exact={true} component={LogoutFallBack} />
              <Route path="*" exact={true} component={HomeFallBack} />
            </Switch>
          </Router>
        )
      }}
    </ThemeContext.Consumer>
  )
}

export default Routes
